@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/CircularTypography.less';
@import (reference) '/src/styles/Breakpoint.less';

.explore-page {
  .ant-btn {
    font-family: @font-family-primary;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;

    &-link {
      font-weight: 500;
      text-decoration: none;
    }
  }
  .low-data-explore {
    .ant-btn {
      font-size: 14px;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 24px;
    .ant-tabs-nav-wrap {
      padding-left: 0;
      font-weight: 500;

      .ant-tabs-tab {
        padding: 8px 0;
        &.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: @text-primary;
        }
      }
    }
  }
  .ant-tabs-content-holder {
    padding: 0;
  }

  .explore-content {
    .p-16 {
      padding: 16px;
    }

    .doughnut-chart-container {
      margin-right: 12px;
      vertical-align: middle;
    }

    .explore-map-container {
      width: 100%;
      height: 100%;
      margin-left: auto;
      position: relative;

      .locations-map {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        overflow: hidden;
      }
    }

    .ant-empty-image {
      height: auto;
      img {
        width: 260px;
      }
    }
    .ant-empty-footer {
      max-width: 350px;
      margin: 0 auto;
    }

    .explore-section {
      margin-bottom: 48px;
      &:last-child {
        margin-bottom: 0;
      }

      .ant-tabs {
        padding-bottom: 0;
      }

      h4 {
        margin-bottom: 14px;

        .goodness-rating {
          display: inline-block;
          font-size: 14px;
          line-height: 14px;
          padding: 6px 12px;
          background: @blue-2;
          border-radius: 20px;
        }
      }
    }
  }

  .confidence-level-panel {
    /* TODO: Update to use border-radius var from Sizing.less */
    border-radius: var(--Border-Radius-borderRadiusLG, 8px);
    overflow: hidden;
    margin-bottom: 48px;

    .confidence-heading {
      /* TODO: Update to use color from Color.less */
      color: var(--Colors-Neutral-Tokens-colorBgBase, #fff);

      font-family: @font-family-primary;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 140% */
    }

    .dark-panel .rainbow-progress {
      margin: 24px 0 32px;
    }

    .confidence-sub-heading {
      /* TODO: Update to use color from Color.less */
      color: var(--Gray-gray-1, #fff);

      font-family: @font-family-secondary;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      margin: 0 0 8px;
    }
  }
}

.explore-summary--page {
  margin-bottom: 16px;
}

.explore-summary {
  .box-panel {
    height: 100%;
    box-sizing: border-box;
  }
  &--value-box {
    width: 100%;
    padding: 10px 8px;
    border-radius: 4px;
    background: @blue-1;
    text-align: center;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.danger {
      background-color: @gold-9;
      .anticon {
        color: @gold-5;
      }
    }
    &.success {
      background-color: @green-9;
      .anticon {
        color: @green-4;
      }
    }
    &.neutral {
      background-color: @blue-1;
      .anticon {
        color: @blue-4;
      }
    }
  }

  // Histograms
  canvas {
    height: 52px;
    max-height: 52px;
    width: 100%;
    border-bottom: 1px solid @gray-4;
  }
}

@primary-color: #2F54EB;
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Spacing';

.search-page {
  .ant-table-column-title {
    text-wrap: wrap;
  }
  &__start {
    .ant-input-affix-wrapper {
      input {
        height: 36px;
      }

      border-radius: 23px;

      .ant-input-prefix {
        font-size: 18px;
        margin-right: 12px;
        color: @gray-6;
      }
    }

    .search-url {
      font-size: 11px;
      line-height: 14px;
    }

    .distance {
      font-size: 11px;
      color: #ffffff;
      background-color: @blue-6;
      padding: 2px 5px;
      border-radius: 4px;
      line-height: 14px;
    }

    .highlight-match {
      background: @green-8;
    }

    table {
      img {
        height: 52px;
        width: auto;
        height: auto;
        max-width: 75px;
      }
    }
  }

  .columns-manager .search-results--table--sorter {
    display: none;
  }

  .mapboxgl-map {
    border: 1px solid @gray-5;
    border-radius: 4px;
  }
}

.index-progress-page {
  margin: 40px;
}

.progress-bar {
  height: 40px;
  width: 100%;
}

.search-results--supplier-outreach {
  margin-bottom: 48px;

  .box-panel {
    height: 100%;
    padding: 12px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .circular-icon {
      display: inline-block;
      position: relative;
      top: 2px;
    }

    .ant-typography {
      font-family: @font-family-secondary;

      strong {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }

      &.body {
        font-size: 12px;
        color: #5d697b;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 24px;
      }
    }

    .bottom {
      margin-top: auto;

      .highlight {
        font-family: @font-family-secondary;
        font-size: 24px;
        line-height: 24px;
        font-weight: 500;
        margin-right: 4px;
      }

      .secondary {
        font-family: @font-family-secondary;
        font-size: 12px;
        line-height: 24px;
        font-weight: 500;
        color: @text-secondary;
      }

      .ant-btn {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        height: auto;
        border-radius: 4px;
      }
    }
    .rate {
      span {
        display: inline-block;
        padding: 0 4px;
        border-radius: 4px;
        font-weight: 600;
        color: @Colors-Brand_Tokens-Primary-colorPrimary;
        background-color: @Colors-Primatives-Circular_Blue-2;
      }
    }

    &.dark {
      .ant-typography {
        &.panel-title {
          color: white;
        }

        &.body {
          color: #aab0bb;
        }
      }
    }
  }
}

.search-results {
  &--controls {
    width: 100%;
    margin-bottom: 15px;

    * {
      font-family: @font-family-secondary;
    }

    .ant-input-affix-wrapper,
    .ant-btn {
      border-radius: 4px;
    }

    .ant-btn {
      font-weight: 500;
    }
  }

  &--table {
    .ant-table {
      .supplier-row {
        .ant-table-cell {
          background-color: #f8f8f8;
        }
      }
      th.ant-table-cell {
        text-wrap: wrap;
        &:not(:nth-child(2)) {
          text-align: center;
        }
      }
    }

    * {
      font-family: @font-family-secondary;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.02em;
    }

    &--sorter {
      display: inline-flex;
      flex-direction: column;
      vertical-align: text-top;
      margin-left: 2px;

      * {
        color: @gray-7;
        font-size: 6px;
      }

      .active * {
        color: @gray-10;
      }
    }

    &--dropdown {
      .ant-dropdown-menu-item {
        padding: 0;

        > span {
          display: block;

          > button,
          > a {
            display: block;
            padding: 5px 12px;
            line-height: 16px;
          }
        }
      }
    }

    .actions {
      * {
        font-size: 18px;
        color: @text-primary;
      }
      button {
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }

        &:disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }
      }
    }

    // Child rows (individual TDS rows)
    .ant-table-row-level-1 {
      .ant-table-selection-column {
        border-right: 1px solid @gray-5;

        label {
          // Don't render checkbox
          display: none;
        }
      }
    }

    .thin {
      font-weight: 400;
    }

    .subcontent {
      display: block;
      font-size: 12px;
      line-height: 12px;
      color: #64748d;
      font-weight: 400;
    }

    .company {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;

      a {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        color: @text-primary;

        &:hover {
          color: @link-blue;
        }
      }
    }
  }
}

.search--proposal {
  .full-screen-modal {
    &--body {
      height: calc(100vh - 56px);
      margin: 0;
      max-width: unset;
    }
  }

  .quotes {
    > .ant-space-item {
      > .ant-row {
        padding-left: 16px;
        padding-right: 16px;

        &:hover:not(.header-row) {
          border: 1px solid @blue-4;
          background: @white;
          box-shadow: 0 2px 0 0 @primary-button-shadow;
        }
      }

      &:first-child > .ant-row {
        margin-bottom: -8px;
      }
    }

    &.is-showing-tds {
      .single-quote {
        &:not(.shown-tds) {
          opacity: 0.6;
        }
      }
    }
  }

  .single-quote {
    border: 1px solid @gray-5;
    border-radius: 8px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
    cursor: pointer;
    padding: 16px;

    .ant-tag {
      margin-left: @margin-xs;
      position: absolute;
      top: -24px;
      left: -48px;
    }
  }

  &--list {
    width: 960px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 32px;
  }

  &--details {
    border-left: 1px solid @gray-5;
    width: 600px;
    min-width: 460px;

    // Prevent panel from inheriting extra unnecessary height
    position: sticky;
    top: 0;
    height: 100vh;
    overflow: scroll;
  }

  .react-markdown {
    font-weight: 400;

    p,
    ul {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    ul + ul {
      margin-top: -8px;
    }
  }
}

@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Spacing';
@import (reference) '/src/styles/Breakpoint.less';

.header {
  padding: @size;
  border-bottom: 1px solid @cool-border-color;
}

.checkboxCard {
  padding: @size;
  border: 1px solid @cool-border-color;
}

.button {
  height: 52px;
}

.radio {
  pointer-events: none;
  .ant-radio-inner {
    background-color: #fff;
  }
}

@media screen and (max-width: @phone) {
  .content {
    width: calc(100% - 2 * @size-lg);
  }
}

@primary-color: #2F54EB;
@import '../Landing.less';

#meet-the-team {
  padding: 130px 24px 50px;

  @media (min-width: @sm-bp) {
    padding: 140px 72px 86px;
  }
  @media (min-width: @lg-bp) {
    padding-top: 150px;
  }

  h2 {
    margin-bottom: 74px;
  }

  .meet-the-team-container {
    width: 944px;
    max-width: 100%;
    margin: 0 auto;

    .team-member {
      text-align: center;
      margin: 0 auto 76px;
      padding: 0px;
      max-width: 220px;

      img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        margin-bottom: 28px;
        background-color: @blue-2;
        object-fit: cover;
        object-position: top;

        @media (min-width: @md-bp) {
          width: 180px;
          height: 180px;
        }
      }

      h6 {
        font-family: @font-family-primary;
        font-size: 16px;
        line-height: 28px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        margin-bottom: 1px;
      }
      .member-role,
      .member-experience {
        font-family: @font-family-primary;
        line-height: 22px;
        font-weight: 400;
      }
      .member-role {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .member-experience {
        font-size: 12px;
        color: #555d70;
      }
    }

    .padding-1em {
      padding: 1em;
    }

    .color-white {
      color: white;
    }

    .background {
      position: absolute;
      left: 20%;
      width: 2054px;
      height: 2059px;
      background: #184649;
      opacity: 0.03;
      z-index: -1;
    }
  }
}

@primary-color: #2F54EB;
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/ColorFigma.less';
@import (reference) '/src/styles/CircularTypography.less';
@import (reference) '/src/styles/Breakpoint.less';

.project-page {
  .filters-sidebar {
    background: #fff;
    flex-grow: 1;
    overflow-y: scroll;

    h1 {
      font-size: 24px;
      line-height: 24px;
      margin-top: 0;
      margin-bottom: 8px;
      font-weight: 600;
      font-family: @font-family-primary;
    }

    h3 {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
    }

    .ant-radio-group.form-filter {
      text-wrap: nowrap;
    }

    .ant-tabs-nav {
      margin-bottom: 24px;
      .ant-tabs-nav-wrap {
        padding-left: 0;
        font-weight: 500;

        .ant-tabs-tab {
          padding: 8px 0;
          &.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: @text-primary;
          }
        }
      }
    }
    .ant-tabs-content-holder {
      padding: 0;
    }

    .ant-collapse {
      background-color: transparent;
      &-header {
        border-bottom: 0;
        background: none;
      }
      &-content {
        border-top: 0;
        background: #f5f5f5;
        padding: 12px;
        border-radius: 8px !important;
        font-weight: 400;

        > .ant-collapse-content-box {
          padding: 0;
        }
      }
      &-header-text {
        font-weight: 500;
      }
      &-item.ghost {
        .ant-collapse-content {
          padding: 0;
          background: transparent;
        }
      }
    }

    .ant-btn {
      height: auto;
      * {
        font-family: @font-family-primary !important;
      }
      &-link {
        color: @link-blue;
        padding: 0;
      }
    }

    .form-filter {
      display: flex;
      margin-bottom: 16px;

      > * {
        flex-basis: 33%;
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        padding: 3px 7px;

        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }
    }
    .permission-warning {
      border-radius: var(--Border-Radius-borderRadiusLG, 8px);
      border: 1px solid @Colors-Brand_Tokens-Info-colorInfoBorder;
      background: @Colors-Brand_Tokens-Info-colorInfoBg;
      padding: 8px 12px;
      margin-bottom: 16px;
      .anticon {
        color: @Colors-Brand_Tokens-Info-colorInfoBorder;
      }
    }
    .filters-box {
      border: 0;

      * {
        font-family: @font-family-secondary;
      }

      > .ant-collapse-item {
        border: 0;
        .ant-collapse-header {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .ant-select {
        width: 100%;

        &-selector {
          border-radius: 6px;
        }

        + * {
          margin-top: 6px;
        }
      }
      .ant-checkbox-group-item,
      .ant-radio-wrapper {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      .ant-input-number-affix-wrapper {
        width: 100%;
        border-radius: 6px;
      }
      .ant-btn-link {
        margin: 8px 0 0;
        font-size: 12px;
      }
      .ant-badge {
        * {
          font-family: @font-family-primary;
          font-size: 10px;
        }
        sup {
          background: @link-blue;
        }
      }
    }

    .kpi-filter {
      .ant-input-number,
      .ant-select-selector {
        border-radius: 6px;
      }

      .ant-select {
        width: 100%;
      }

      .ant-slider {
        margin-bottom: 0;
        margin-top: -3px;
      }

      .units {
        font-size: 0.8em;
        color: @text-secondary;
        display: inline-block;
      }

      .minmax {
        font-size: 10px;
        margin-top: 8px;
        .ant-input-number {
          width: 100%;
        }
        .ant-select {
          &-selection-item {
            padding-inline-end: 0;
          }
        }
      }

      &--single {
        margin: 12px 0;
        position: relative;

        * {
          font-family: @font-family-secondary;
        }

        .ant-checkbox {
          width: 0;
          overflow: hidden;
          pointer-events: none;
          + span {
            padding: 0;
          }
        }

        &:hover {
          .kpi-filter--remove {
            opacity: 1 !important;
          }
        }
      }

      &--remove {
        position: absolute;
        top: -8px;
        right: -8px;
        font-size: 16px;

        &:not(:hover) {
          opacity: 0;
        }
      }

      &--required {
        .circular-icon {
          margin-right: 4px;
          path {
            fill: #d2d6db;
          }
        }
        &.is-required .circular-icon path {
          fill: #2f54eb;
        }
      }

      &--active {
        font-size: 12px;
        font-weight: 500;

        .anticon {
          margin-right: 4px;
          cursor: pointer;

          &:not(:hover) {
            color: @text-secondary;
          }
        }
      }
    }
    .setting-switch-label {
      display: inline-block;
      padding: 0 0 0 8px;
      font-family: @font-family-secondary;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

@sm-bp: 576px;
@md-bp: 768px;
@lg-bp: 992px;
@xl-bp: 1200px;
@xxl-bp: 1600px;

@media (max-width: @sm-bp) {
  .show-sm {
    display: none !important;
  }
}

@media (max-width: @md-bp) {
  .show-md {
    display: none !important;
  }
}

@media (max-width: @lg-bp) {
  .show-lg {
    display: none !important;
  }
}

@media (max-width: @xl-bp) {
  .show-xl {
    display: none !important;
  }
}

@media (max-width: @xxl-bp) {
  .show-xxl {
    display: none !important;
  }
}

@media (min-width: calc(@sm-bp + 1px)) {
  .hide-sm {
    display: none !important;
  }
}

@media (min-width: calc(@md-bp + 1px)) {
  .hide-md {
    display: none !important;
  }
}

@media (min-width: calc(@lg-bp + 1px)) {
  .hide-lg {
    display: none !important;
  }
}

@media (min-width: calc(@xl-bp + 1px)) {
  .hide-xl {
    display: none !important;
  }
}

@media (min-width: calc(@xxl-bp + 1px)) {
  .hide-xxl {
    display: none !important;
  }
}

.onboard-home {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  background-color: #fff;

  .onboard-home-content {
    flex: 1024px 0 1;
    margin: 0 auto;
    padding: 48px;

    .onboard-header {
      margin-bottom: 56px;

      button {
        color: @gray-9;
        border: 1px solid rgba(0, 0, 0, 0.25);
        margin-top: 7px;
        margin-right: 16px;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .title-col {
        margin-right: auto;

        h1 {
          .heading-xl();
          margin-bottom: 8px;
          margin-right: 24px;
          + .ant-typography {
            .font-size-3();
            color: #606060;
          }
          ~ .ant-tag {
            background: transparent;
            color: #606060;
            text-transform: capitalize;
            margin: 0 0 0 16px;
            &.ant-tag-success {
              color: #389e0d;
              border-color: #52c41a;
            }
          }
        }
      }
    }

    .progress-bar {
      padding: 32px;
      margin-bottom: 48px;
      background-color: #f9fbfc;

      .progress-bar-step {
        flex: 20% 0 0;
        font-family: @font-family-secondary;
        font-weight: 400;

        > div {
          width: 100%;
          border-bottom: 3px solid @gray-3;
          padding-bottom: 8px;
          font-size: 12px;
          line-height: 18px;
          color: #999;

          .anticon {
            color: @secondary-color;
            margin-right: 9px;
            display: none;
          }
        }

        &.completed > div,
        &.current > div {
          border-color: @secondary-color;
          color: @text-primary;
        }
        &.completed {
          opacity: 0.5;
          .anticon {
            display: inline-block;
          }
        }
      }
    }

    .step-content {
      > div.ant-typography {
        color: #606060;
        .font-size-3();
      }

      .document-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }

        .icon {
          display: inline-block;
          width: 40px;
          height: 40px;
          text-align: center;
          background: rgba(47, 84, 235, 0.08);
          border-radius: 11px;
          color: @primary-color;
          font-size: 15px;
          line-height: 40px;
          margin-right: 24px;
          + span {
            .font-size-4();
          }
        }

        .actions {
          button {
            .font-size-2();
            font-weight: 600;
            color: @gray-9;
          }
          > * {
            margin-left: 8px;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

.onboard {
  display: flex;
  width: 100%;
  height: 100%;

  button {
    cursor: pointer;
  }

  .onboard-sidebar {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    padding: 48px 64px;
    background: linear-gradient(173.66deg, #19344b 1.9%, #142333 97.52%);
    color: #fff;
    font-family: @font-family-secondary;
    overflow: overlay;
    min-width: 24px;
    box-sizing: content-box;

    &.collapsed {
      padding-left: 28px;
      padding-right: 28px;
      h3,
      .step-title,
      .step-content > * {
        display: none !important;
      }
    }

    * {
      color: inherit;
      font-family: inherit;
    }

    .sidebar-header {
      display: flex;
      align-items: center;
      margin-bottom: 48px;

      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin: 0;
        margin-left: 24px;
      }
    }

    .sidebar-steps {
      margin-bottom: 24px;
      .step {
        margin-bottom: 8px;

        .step-header {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          .step-number {
            min-width: 24px;
            max-width: 24px;
            height: 24px;
            border-radius: 50%;
            font-family: @font-family-primary;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            border: 1px solid rgba(255, 255, 255, 0.25);
            box-sizing: border-box;
          }
          .step-title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-left: 24px;
          }
        }

        .step-content {
          margin-left: 12px;
          border-left: 1px solid rgba(255, 255, 255, 0.16);
          padding: 8px 0 12px 0;
          .active-text {
            font-family: @font-family-primary;
            font-size: 14px;
            line-height: 22px;
            opacity: 0.65;
            display: none;
            p {
              margin-bottom: 12px;
            }
          }
          .sub-steps {
            display: none;
            p {
              margin: 8px 0;
            }
            .sub-step.active {
              font-weight: 700;
            }
          }
        }

        &.active,
        &.completed {
          .step-number {
            border: 0;
            background-color: @primary-color;
          }
        }
        &.active {
          .sub-steps {
            display: block;
            margin-left: 36px;
          }
          .active-text {
            display: block;
          }
        }

        &:last-child {
          .step-content {
            display: none;
          }
        }
      }
    }

    .sidebar-footer {
      .ant-space {
        width: 100%;
        .ant-space-item {
          .anticon {
            margin-left: 8px;
          }
          button {
            .anticon {
              margin-left: 0;
            }
          }
        }
      }
      text-align: left;
      margin-top: auto;
      img {
        width: 104px;
        margin-bottom: 32px;
      }
      span {
        font-size: 16px;
        line-height: 28px;
        font-weight: 300;
        opacity: 0.75;
        margin: 0 7px 12px 0;
      }
      button.collapse {
        border: 1px solid rgba(255, 255, 255, 0.25);
        font-size: 12px;
        line-height: 22px;
        padding: 3px 6px;
        width: 32px;
        height: 32px;
        float: right;
      }
    }
  }

  .onboard-content {
    flex-grow: 1;
    position: relative;
    overflow: overlay;

    h1 {
      .heading-xl();
    }

    .ant-divider-inner-text {
      color: @text-hint;
      font-weight: 300;
      .font-size-2();
    }

    .ant-divider {
      padding: 0 0 24px 0;
    }

    .upload-documents {
      .ant-upload.ant-upload-drag {
        background: #f9fbfc;
        height: 96px;
        padding: 32ps 0 46px 0;
        .anticon {
          font-size: 28px;
          color: @gray-5;
          position: relative;
          /* Adjust these values accordingly */
          top: 5px;
          left: -15px;
        }
      }

      .upload-file-description {
        .font-size-2();
        color: @gray-6;
        .anticon {
          .font-size-2();
          position: relative;
          /* Adjust these values accordingly */
          top: 1px;
          left: 5px;
        }
      }

      .browse {
        color: @primary-color;
      }
    }

    > .ant-form {
      min-height: 100%;
      display: flex;
    }

    .ant-alert {
      margin-bottom: 24px;

      .ant-alert-description > div::first-letter {
        text-transform: capitalize;
      }
    }

    .slides {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 72px 64px 72px 152px;
      min-height: 100%;
      max-width: 1000px;
      flex: 1;
      .slides-content {
        flex: 0 1 600px;

        .ant-form-item {
          margin-bottom: 48px;
          flex-wrap: nowrap;

          .ant-form-item-label {
            padding-bottom: 24px;
            label {
              font-family: @font-family-secondary;
              font-weight: 500;
              font-size: 20px;
              line-height: 28px;
            }
          }

          .ant-form-item-extra {
            order: -1;
            margin-top: -16px;
            margin-bottom: 24px;
          }

          .ant-radio-group,
          .ant-checkbox-group {
            width: 100%;

            .ant-radio-wrapper,
            .ant-checkbox-wrapper {
              display: flex;
              flex-wrap: nowrap;
              margin-bottom: 8px;
              margin-left: 0;
              > * {
                display: inline-block;
              }
            }
          }

          .ant-radio-button-wrapper {
            width: 100%;
            padding: 16px 24px;
            text-align: center;
            height: auto;
            font-size: 14px;
            line-height: 22px;
            border-radius: 4px;
            margin-bottom: 16px;
            font-weight: 400;

            &.ant-radio-button-wrapper-checked {
              background: rgba(47, 84, 235, 0.04);
            }
          }

          .price-group {
            > div:first-child {
              margin-right: 8px;
              width: 120px;
            }
          }

          .ant-input-group-compact .ant-form-item {
            margin-bottom: 0;
          }
        }
      }
      .slides-nav {
        flex: 0 0 88px;
        align-self: flex-end;
        text-align: right;
        button {
          font-size: 12px;
          width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 50%;
          text-align: center;
          box-sizing: border-box;

          &.prev-slide {
            color: @primary-color;
            border: 1px solid @primary-color;
          }
          &.next-slide {
            color: #fff;
            background-color: @primary-color;
            margin-left: 8px;
          }
        }
      }
    }

    .generating-onboard-wrap {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      .generating-onboard {
        flex: 0 1 600px;
        text-align: center;
        padding: 38px;

        h2 {
          font-size: 30px;
          line-height: 38px;
          font-weight: 400;
          margin-bottom: 16px;
          + div {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 32px;
          }
        }

        .progress-bar {
          height: 5px;
          width: 100%;
          border-radius: 5px;
          background: @gray-4;
          position: relative;
          overflow: hidden;
          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            // min-width: 20px;
            width: 100%;
            animation: progress-bar 1s ease-in-out;
            height: 100%;
            border-radius: 5px;
            background: linear-gradient(
              114.01deg,
              #2f51ff 27.74%,
              #5132ff 37%,
              #f65dff 57.69%,
              #eec7a0 72.26%
            );
          }
        }

        .dummy-quote {
          margin-top: 72px;
          padding: 24px 32px 0;
          text-align: left;
          border: solid 1px @gray-5;
          border-bottom: 0;
          position: relative;

          &::after {
            content: '';
            display: block;
            height: 100%;
            background: linear-gradient(to bottom, transparent, white 50%);
            position: absolute;
            bottom: 0;

            // Extra pixel to cover borders
            left: -1px;
            width: calc(100% + 2px);

            animation: dummy-quote 1s linear;
            transform: translateY(50%);
          }

          .quote-details {
            width: 100%;
            td {
              text-align: right;
            }
          }
        }
      }
    }

    .confirmed-wrap {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      .confirmed {
        flex: 0 1 600px;
        text-align: center;
        padding: 76px 0;

        h2 {
          font-size: 30px;
          line-height: 38px;
          font-weight: 400;
          margin-bottom: 16px;
          + div {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 56px;
            color: #606060;
          }
        }
        p {
          .font-size-4();
        }

        .square-bullets {
          text-align: left;
          font-size: 16px;
          line-height: 24px;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          padding: 24px 49px;
          .bullet {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            .bullet-icon {
              display: block;
              margin-right: 24px;
              width: 40px;
              height: 40px;
              background-color: rgba(47, 84, 235, 0.08);
              border-radius: 8px;
              flex: 0 0 40px;
              margin-bottom: auto;

              img {
                width: 24px;
                height: 24px;
                margin: 8px;
              }
            }

            .ant-typography-secondary {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }

        .buttons {
          margin-top: 56px;
          > *:first-child {
            margin-right: 8px;
          }
        }
      }
    }
    .onboard-approved {
      align-items: center;
      justify-content: center;
      text-align: left;
      max-width: 100%;
      width: 600px;
      padding: 102px 0 20px;
      margin: 0 auto;

      .onboard-approved-banner {
        text-align: center;
        p {
          color: @text-secondary;
        }
        h1 {
          .heading-xl();
        }
        h5 {
          margin-top: 76px;
          .font-size-1();
          text-transform: uppercase;
        }
      }
    }
  }
}

@keyframes progress-bar {
  0% {
    width: 0%;
  }
}

@keyframes dummy-quote {
  0% {
    transform: translateY(0%);
  }
}

.rfq-detail {
  margin-right: 32px;
  margin-left: 32px;
  white-space: nowrap;
  &:last-child {
    margin-right: 0;
  }
  .resin-code img {
    width: 28px;
    height: 28px;
  }

  div.ant-typography {
    text-transform: uppercase;
    .font-size-2();
    color: #606060;
    margin-bottom: 8px;

    + div {
      font-size: 22px;
      line-height: 38px;
      color: #000;
      .commodity-symbol-outline {
        display: inline-block;
      }
      .commodity-symbol-outline,
      .commodity-symbol {
        width: 20px;
        height: 20px;
      }
      .units {
        color: #999;
        .font-size-3();
      }
    }
  }
}

.tds-detail {
  margin-right: 32px;
  margin-left: 32px;
  white-space: nowrap;
  &:last-child {
    margin-right: 0;
  }
  .resin-code img {
    width: 28px;
    height: 28px;
  }

  div.ant-typography {
    .font-size-2();
    color: #606060;
    margin-bottom: 8px;

    + div {
      font-size: 22px;
      line-height: 38px;
      color: #000;
      .commodity-symbol-outline {
        display: inline-block;
      }
      .commodity-symbol-outline,
      .commodity-symbol {
        width: 20px;
        height: 20px;
      }
      .units {
        color: #999;
        .font-size-3();
      }
    }
  }
}

.buyer-process-overview {
  min-height: 100vh;
  h1 {
    .heading-xl();
  }
  button {
    width: 156px;
    height: 40px;
    .font-size-4();
  }
}

.process-steps {
  &.box {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 16px;
    margin: 16px 0;
  }
  max-width: 660px;
  margin: 48px 0;
  font-family: @font-family-primary;
  .step {
    margin-bottom: 8px;
    .step-header {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      flex: 0 1 660px;

      .step-number {
        flex: 0 0 24px;
        height: 24px;
        border-radius: 50%;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        border: 1px solid;
        background: linear-gradient(white, white) padding-box,
          linear-gradient(
              158.26deg,
              #2fa4ff 0%,
              #326cff 30.11%,
              #e55dff 62.14%,
              #eeb0a0 92.35%
            )
            border-box;
        border: 1px solid transparent;
        // border-image-source: linear-gradient(158.26deg, #2FA4FF 0%, #326CFF 30.11%, #E55DFF 62.14%, #EEB0A0 92.35%);
        box-sizing: border-box;
        margin-right: 24px;
        &.complete {
          color: #ffffff;
          background: @secondary-color;
        }
      }
      .step-title-row {
        display: flex;
        flex: 0 1 628px;
        flex-direction: row;
        .step-title {
          .subtitle-lg;
          font-weight: 300;
          flex: 1;
        }
        .step-duration {
          font-size: 12px;
          line-height: 21px;
          font-weight: 300;
          flex: 0 0 90px;
          text-align: right;
          color: @text-secondary;
        }
        .anticon {
          position: relative;
          top: 4px;
          flex: 0 0 32px;
          color: @gray-6;
        }
      }
    }

    .step-content {
      margin-left: 12px;
      border-left: 1px solid rgba(47, 84, 235, 0.16);
      padding: 8px 0 12px 0;
      font-weight: 300;
      .background-row {
        display: flex;
        margin-bottom: 12px;
        flex-direction: row;
        margin-left: 36px;
        background-color: @gray-2;
        padding: 8px 0 8px 16px;
        .background-text {
          flex: 1;
          font-size: 14px;
          line-height: 22px;
          font-weight: 300;
        }
        .background-duration {
          font-size: 12px;
          line-height: 21px;
          font-weight: 300;
          flex: 0 0 90px;
          text-align: right;
          color: @text-secondary;
        }
        .anticon {
          position: relative;
          top: 4px;
          flex: 0 0 32px;
          color: @gray-6;
        }
      }
    }

    &.active,
    &.completed {
      .step-number {
        border: 0;
        background-color: @blue-6;
      }
    }
    &.active {
      .active-text {
        display: block;
      }
    }

    &:last-child {
      .step-content {
        display: none;
      }
    }
  }
}

.supplier-avatar {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.review {
  &__modal {
    .ant-modal-footer {
      display: flex;
      padding: 1rem 2rem;
      button {
        width: 50%;
      }
    }
    .center {
      padding: 6rem 4rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #000000;
        width: 100%;
      }

      .message {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #000000;
      }
    }

    .change--title {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #323640;
      display: block;
      margin-bottom: 1.25rem;
    }
  }

  &__header {
    // justify-content: space-between;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    background: white;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 16px;
    padding-left: 120px;
    .ant-divider {
      height: auto;
    }
    border-bottom: 1px solid #f0f0f0;

    box-shadow: 0px 4px 24px rgba(42, 55, 75, 0.07);
    .admin-console-link {
      flex-grow: 1;
      .anticon {
        float: right;
      }
    }
  }

  &__content {
    margin-top: 2rem;
    padding: 0;
    margin: 72px 120px 0 120px;
    max-width: 800px;

    .ant-card-body {
      box-sizing: border-box;
      gap: 8px;
      background: #ffffff;
      border: 1px solid #f5f5f5;
      border-radius: 8px;
      box-shadow: 0 4px 24px rgba(42, 55, 75, 0.07);
    }

    &--title {
      padding: 1rem 0 3rem 0;
      justify-content: space-between;

      h1 {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 38px;
        color: #000000;
      }

      .title,
      .created_at {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
      }

      .purchaser {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 8px;

        &__title {
          font-family: Poppins, sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #000000;
        }

        &__name,
        &__email,
        &__company {
          font-family: Poppins, sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 22px;
          color: #000000;
        }
      }
    }

    &--list {
      .ant-list {
        width: 100%;
        .ant-list-item {
          // flex-wrap: wrap;
          .ant-form-item {
            width: 100%;
            margin-bottom: 0px;

            &.wide {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  display: flex;
                  flex-wrap: wrap;
                  width: 100%;
                  .form-control-embedded-label {
                    margin-bottom: 8px;
                    flex: 0 0 100%;
                  }
                  > :not(:first-child) {
                    flex: 0 0 100%;
                  }
                }
              }

              .ant-checkbox-group {
                flex-direction: column;

                .ant-checkbox-wrapper {
                  margin-bottom: 6px;
                }
              }
            }

            .scores-weight {
              > p {
                margin-top: 0;
                margin-bottom: 24px;
              }
              .ant-radio-group {
                width: 100%;
                > * {
                  width: 20%;
                  text-align: center;
                }
              }

              &--row {
                margin-bottom: 16px;
              }
            }

            .ant-typography {
              flex: 0 0 66.6667%;
            }
            .form-control-embedded-label {
              font-weight: 600;
              text-align: left;
            }
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                .form-control-embedded-label {
                  flex: 0 0 60%;
                }
                .form-control--before {
                  color: #64748d;
                  font-style: italic;
                  font-style: 14px;
                  font-weight: 400;
                  line-height: 22px;
                  margin-bottom: 20px;
                }
                > :nth-child(2) {
                  flex: 0 0 40%;
                }
                > button:nth-child(2) {
                  flex: 0 0 0;
                  margin-left: auto;
                }
              }
            }
            .switchable-component {
              margin-top: 16px;
              width: 100%;
              padding: 24px;
              background-color: @gray-4;
            }
            .ant-alert {
              flex-basis: 100%;
              margin-top: 8px;
              margin-bottom: 0;
            }
          }
          .ant-select {
            min-width: 250px;
          }
          .range-input {
            .ant-select {
              min-width: 120px;
              max-width: 150px;
            }
          }
        }
        &-header {
          margin-top: 3rem;

          .ant-typography {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #606060;
          }
        }
      }
    }
  }
  .actions {
    width: 100%;
    height: 88px;
    background-color: #ffffff;
    .action-wrapper {
      width: 100%;
      margin: 0 0 0 120px;
      max-width: 800px;
      height: 88px;
      position: relative;
      button {
        float: right;
        height: 40px;
        position: absolute;
        position: absolute;
        right: 0;
      }
      padding: 24px;
    }
  }
}

@media print {
  .onboard {
    height: auto;
    .onboard-sidebar {
      display: none;
    }
  }

  .review {
    .actions {
      display: none;
    }
    &__content--details {
      display: none;
    }
  }
}

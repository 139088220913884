@import '../Landing.less';

.landing-header-wrap {
  padding: 20px 34px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 4px 20px rgb(0 0 0 / 5%);
  transition: all 0.3s ease;

  @media (min-width: @lg-bp) {
    padding: 34px;
  }

  &.transparent {
    background: transparent;
    box-shadow: none;
  }

  &.hidden {
    top: -100px;
  }

  .landing-menu {
    max-width: 1224px;
    margin: 0 auto;
  }

  .menu-item {
    text-transform: uppercase;
    font-family: @font-family-primary;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.05em;
    font-weight: 600;
  }
}

@primary-color: #2F54EB;
.stack-container {
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  align-items: flex-start;
  .stackmenu-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    white-space: nowrap;
    margin: 0 auto 32px;

    // Mobile prev / next buttons
    > button {
      padding: 0 8px;
      background: @gray-2;
      margin: 0 8px;
    }

    @media (min-width: @lg-bp) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 31.5px;
      width: 322px;
      overflow-y: hidden;

      /* Elevation */
      background: #ffffff;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      border-radius: 4px;

      > button {
        display: none;
      }
    }

    > .stackmenu-item {
      display: none;

      &.mobile-stackmenu-item {
        display: block;
        position: relative;
        padding: 0 16px;
        background: @gray-2;
        min-width: 200px;

        > button {
          background: none;
          margin: 0 auto;
        }

        .stackmenu-steps {
          display: none;
          position: absolute;
          padding: 0 24px;
          background: white;
          left: 50%;
          transform: translate(-50%);
          top: -2px;
          box-shadow: 0px 9px 28px 8px rgb(0 0 0 / 5%),
            0px 6px 16px rgb(0 0 0 / 8%), 0px 3px 6px -4px rgb(0 0 0 / 12%);
          border-radius: 4px;
          z-index: 1;
          &.expanded {
            display: block;
          }
        }
      }
      .stackmenu-divider {
        box-sizing: border-box;
        margin: 0 3.5px;
        padding: 0;
        font-size: 14px;
        list-style: none;
        border-top: 1px solid rgba(0, 0, 0, 0.06);
      }

      @media (min-width: @lg-bp) {
        width: 100%;
        height: 66px;
        display: block;
        &.mobile-stackmenu-item {
          display: none;
        }
      }

      button {
        border: none;
        padding: 0;
        background-color: #fff;
        padding: 8px 0;
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        display: flex;
        flex-direction: row;

        @media (min-width: @lg-bp) {
          width: 100%;
          padding: 21px 0 3px 0;
        }
      }
      .stackmenu-labels {
        display: flex;
        flex-direction: column;
        font-family: 'Roboto', sans-serif;
        .stackmenu-sublabel {
          color: @gray-6;
          font-size: 12px;
          font-weight: 300;
          font-family: 'Poppins', sans-serif;
          min-height: 18px;
        }
      }
      .anticon {
        margin-right: 9.5px;
        font-size: 21px;
        position: relative;
        top: 3px;
        &.done {
          color: @secondary-color;
        }
        &.busy {
          color: @secondary-color;
        }
        &.todo {
          color: @gray-6;
        }
        &.error {
          color: @red-5;
        }
      }
    }
  }

  .stackpage-container {
    width: 100%;
    padding: 0 25px 25px 25px;
    &.hidden {
      display: none;
    }
    &.visible {
      display: block;
    }
  }
  .stack-navigation {
    padding: 25px 0 25px 0;
    button {
      width: calc(50% - 10px);
      &:first-child {
        margin-right: 10px;
      }
      &:nth-child(2) {
        margin-left: 10px;
      }
      height: 40px;
    }
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
  }
  h3 {
    font-size: 20px;
  }
  h5 {
    font-size: 12px;
  }
}

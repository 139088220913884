ul.timeline {
  box-sizing: border-box;
  list-style: none;
  height: auto;
  margin-bottom: 0;
  padding-inline-start: 0;
  li.timeline-item {
    display: flex;
    flex-direction: row;
    list-style: none;
    position: relative;
    height: auto;
    width: auto;
    .timeline-item-content-left {
      flex: 0 0 calc(50% - 36px);
      display: block;
      position: relative;
      order: 1;
    }
    .timeline-item-content-right {
      flex: 0 0 calc(50% - 36px);
      display: block;
      position: relative;
      order: 3;
    }
    &.alternate {
      .timeline-item-content-left {
        order: 3;
      }
      .timeline-item-content-right {
        order: 1;
        text-align: right;
      }
    }
    .stack-container {
      order: 10;
      display: flex;
      flex-direction: column;
      gap: 72px;
      padding-bottom: 8px;
      .timeline-item-content-left {
        order: 3;
        width: 100%;
        flex: 1;
      }
      .timeline-item-content-right {
        order: 2;
        text-align: left;
        width: 100%;
        flex: 1;
        padding-right: 0 !important;
      }
    }

    .line-container {
      flex: 0 0 72px;
      order: 2;
      position: relative;
      .timeline-item-head {
        width: 8px;
        height: 8px;
        display: block;
        position: absolute;
        left: calc(50% - 7px);
        top: 0;
        border: 4px solid @primary-color;
        border-radius: 50%;
        &.inactive {
          border: 4px solid #CCD5F6;

        }
      }
      .timeline-item-tail {
        width: 2px;
        position: absolute;
        border-left: 4px solid @primary-color;
        height: calc(100% - 28px);
        top: 22px;
        &.no-head {
          top: 0;
          height: calc(100%);
        }
        &.inactive {
          border-left: 4px solid #CCD5F6;
        }
        left: calc(50% - 1px);
        display: block;
      }
    }
    @media (max-width: 1200px) {
      &.alternate,&:not(.alternate) {
        padding-right: 72px;
        .timeline-item-content-left {
          order: 5;
          padding-bottom: 72px;
        }
        .timeline-item-content-right {
          order: 4;
          text-align: left;
          padding-right: 92px;
        }  
      }
      &.heading {
        .timeline-item-content-right {
          text-align: left !important;
          flex: 1 0;
        }
        .timeline-item-content-left {
          flex: 0 1;
        }
        .stack-container {
          .timeline-item-content-left {
            padding: 0 !important;
          }  
          .timeline-item-content-right {
            padding: 0 !important;
          }  
        }
      }
    }            
  }
}


.splash-page.credits {
  .bullet-list {
    padding-top: 58px;
    .font-size-4();
    font-family: @font-family-secondary;
    .bullet-list-item {
      padding-bottom: 36px;
    }
  }
  .sc-header {
    .heading-2xl();
    font-weight: 400;
  }
  .sc-subheader {
    .font-size-4();
    margin-top: 16px;
    margin-bottom: 42px;
  }
  .splash-content-body {
    max-width: 600px;
    margin-left: 120px;
    margin-top: 96px;
  }
  .ant-form-item {
    margin-bottom: 40px;
    .ant-form-item-label label {
      font-weight: 600;
    }
    &.query-type {
      margin-bottom: 0px;
    }
  }
  .ant-checkbox-group {
    max-width: 400px;
    .ant-checkbox-wrapper {
      padding-bottom: 12px;
    }
  }
  .sidebar-steps {
    margin-top: 100px;
    margin-bottom: 24px;
    .step {
      margin-bottom: 8px;

      .step-header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .step-number {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          font-family: @font-family-secondary;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          border: 1px solid rgba(255, 255, 255, 0.25);
          box-sizing: border-box;
          margin-right: 24px;
        }
        .step-title {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          font-family: @font-family-secondary;
        }
      }

      .step-content {
        margin-left: 12px;
        border-left: 1px solid rgba(255, 255, 255, 0.16);
        padding: 8px 0 12px 36px;
        .active-text {
          font-size: 14px;
          line-height: 22px;
          opacity: 0.65;
          display: none;
          p {
            margin-bottom: 12px;
          }
        }
      }

      // &.active {
      //   .step-number {
      //     border: 0;
      //     background-color: @blue-6;
      //   }
      //   .active-text {
      //     display: block;
      //   }
      // }

      &:last-child {
        .step-content {
          display: none;
        }
      }
    }
  }
}
@import '../Landing.less';

#latest-news {
  background: linear-gradient(106.22deg, #f5fafe 26.61%, #f3fafe 94.09%);
  padding: 80px 24px 110px;
  overflow: hidden;

  @media (min-width: @lg-bp) {
    padding-top: 120px;
    padding-bottom: 150px;
  }

  .latest-news-inner {
    max-width: 1190px;
    margin: 0 auto;
  }

  h2 {
    margin-bottom: 10px;

    + p {
      margin-bottom: 60px;
      color: #555d70;
      font-size: 20px;
      line-height: 32px;

      @media (min-width: @lg-bp) {
        margin-bottom: 100px;
      }

      a {
        color: @link-blue;
        text-decoration-thickness: 1px;
      }
    }
  }

  .latest-news-items {
    .ant-col {
      a {
        display: grid;
        grid-template-rows: 36px auto;
        gap: 25px;
      }

      img {
        max-height: 36px;
        max-width: 180px;
        align-self: end;
      }

      h3 {
        font-size: 22px;
        line-height: 32px;
        font-weight: 500;
      }
    }
  }
}

@primary-color: #2F54EB;
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Spacing';
@import (reference) '/src/styles/Breakpoint.less';
@import (reference) '/src/styles/CircularTypography.less';

@header-height: 62px;

.supplier-page {
  font-weight: 400;
  &--with-sidebar {
    margin-left: @margin-xxl;
  }

  h1,
  h2 {
    margin: 0;
  }

  .ant-avatar {
    margin-right: 6px;
  }

  .anticon:not(:last-child) {
    margin-right: 4px;
  }

  .ant-tabs {
    padding-bottom: 40px;

    &-nav {
      margin-bottom: 0;
    }

    &-nav-wrap {
      padding-left: 32px;
      font-family: @font-family-secondary;
      font-weight: 500;
      letter-spacing: 0.3px;
    }
    &-tab {
      padding-top: 0;
      &-btn {
        color: #979fac;
        .anticon {
          padding-right: 4px;
        }
      }
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: @text-primary;
      }
    }
    &-ink-bar {
      background: @primary-color;
    }
    &-content-holder {
      padding: 32px;
    }
  }

  .ant-list-items {
    > div {
      border: 1px solid @gray-5;
      border-radius: 4px;
      padding: 12px 16px;
      margin-bottom: 16px;
      > .ant-col {
        font-size: 12px;
        &:first-child {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  &--header {
    background-color: #11193a;
    padding: 16px 24px;
    height: @header-height;

    .ant-col {
      line-height: 1;
    }
    .circular-logo {
      height: 27px;
      width: auto;
    }
  }

  .supplier-onboarding {
    &--sidebar {
      // background: @link-blue;
      padding: 24px;

      &--inner {
        display: flex;
        margin-left: auto;
        * {
          color: white;
        }
        h1 {
          font-size: 47px;
          line-height: 60px;
          font-family: @font-family-primary;
        }
        div.ant-typography {
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          color: #c8cad4;
          letter-spacing: 0.16px;
        }
      }
    }

    &--content {
      padding: 24px;

      &--inner {
        max-width: 555px;

        .ant-form-item {
          label {
            font-size: 16px;
            line-height: 29px;
            font-style: normal;
            font-weight: 500;
          }
          .ant-select-single {
            height: auto;
          }
          .ant-input,
          .ant-select-selector,
          .ant-input-number {
            border-radius: 3px;
            border: 1.5px solid #c8cad4;
            padding: 16px 24px;
          }
          .ant-input-number {
            min-width: 200px;
          }
          .ant-input-number-group {
            .ant-input-number {
              border-radius: 3px 0 0 3px;
              border: 1.5px solid #c8cad4;
              padding: 16px 12px;
            }
            .ant-input-number-group-addon {
              border-radius: 0 3px 3px 0;
              border: 1.5px solid #c8cad4;
              border-left-width: 0;

              .ant-select.ant-select-outlined {
                .ant-select-selector {
                  border: 0;
                }
              }
            }
          }
          .ant-form-item-extra {
            margin-top: 16px;
          }
          .ant-checkbox-wrapper {
            span {
              font-weight: 400;
            }
          }
        }

        .ant-btn {
          // background: #0d33cc;
          padding: 12px 23px;
          height: auto;
          margin-top: 25px;
          .ant-typography {
            color: white;
          }
        }
      }
    }

    @media (min-width: @lg-bp) {
      position: fixed;
      top: @header-height;
      left: 0;
      width: 100%;
      height: calc(100% - @header-height);

      &--sidebar,
      &--content {
        height: 100%;
        overflow-y: scroll;
      }
      &--sidebar {
        padding: 100px 50px;
        &--inner {
          max-width: 465px;
        }
      }
      &--content {
        padding: 60px;
      }
    }
  }

  .supplier-profile {
    &--sidebar {
      padding: 32px 24px;
      border-right: 1px solid @gray-5;

      .ant-space {
        width: 100%;
      }

      .mapboxgl-map {
        height: 200px;
        width: 100%;
        border-radius: 6px;
        border: 1px solid @gray-5;
      }
    }

    &--types {
      &--single {
        background-color: @gold-8;
        padding: 8px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 1;
        font-weight: 500;
      }
    }

    &--certs {
      &--single {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &--content {
      padding: 32px;
      &--header {
        padding-bottom: 32px;
      }
    }

    &--materials-table {
      .ant-table {
        border-radius: 6px;
        border: 1px solid @gray-5;
        .ant-table-body tr:last-of-type td {
          border-bottom: 0;
          border-radius: 0 0 6px 6px;
        }
        .supplier-row {
          .ant-table-cell {
            background-color: #f8f8f8;
          }
        }
      }

      *:not(.ant-typography) {
        font-family: @font-family-secondary;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.02em;
      }

      .sorter {
        display: inline-flex;
        flex-direction: column;
        vertical-align: text-top;
        margin-left: 2px;

        * {
          color: @gray-7;
          font-size: 6px;
        }

        .active * {
          color: @gray-10;
        }
      }

      &--dropdown {
        .ant-dropdown-menu-item {
          padding: 0;
          > span {
            display: block;
            > button,
            > a {
              display: block;
              padding: 5px 12px;
              line-height: 16px;
            }
          }
        }
      }

      &--controls {
        width: 100%;
        margin-bottom: 15px;
        * {
          font-family: @font-family-secondary;
        }

        .ant-input-affix-wrapper,
        .ant-btn {
          padding-left: 7px;
          border-radius: 4px;
        }

        .ant-btn {
          font-weight: 500;
        }
      }

      .ant-table-column-title,
      th {
        color: #64748d !important;
        font-weight: 400 !important;

        // This is what ant uses for th borders
        &::before {
          display: none;
        }
      }
      .ant-table-column-sorter {
        display: none;
      }

      .ant-table-cell {
        padding: 14px 11px;
        background-color: white !important;
        white-space: pre-line;

        // We don't have a better way to control per-column borders
        &.ant-table-cell-fix-left-last {
          border-right: 3px solid @gray-4;
          + * {
            padding-left: 24px;
          }

          + * + * {
            border-right: 1px solid @gray-4;
            + * {
              padding-left: 24px;
            }
          }
        }
      }
      th.ant-table-cell {
        white-space: nowrap;
        padding: 9px 14px !important;
      }
      .ant-table-summary {
        .ant-table-cell {
          background: @gray-2;
          border-bottom: 3px solid @gray-4;
        }
      }

      // Child rows (individual TDS rows)
      .ant-table-row-level-1 {
        .ant-table-selection-column {
          border-right: 1px solid @gray-5;
          label {
            // Don't render checkbox
            display: none;
          }
        }
      }

      .thin {
        font-weight: 400;
      }

      .subcontent {
        display: block;
        font-size: 12px;
        line-height: 12px;
        color: #64748d;
        font-weight: 400;
      }

      .company {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        a {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          color: @text-primary;
          &:hover {
            color: @link-blue;
          }
        }
      }
    }
  }
}

#seller-onboard {
  .material-list {
    > .ant-card {
      .ant-card-body {
        padding: 32px;
        button {
          float: right;
        }
        > .ant-row {
          margin-bottom: 16px;
        }
        .ant-card {
          cursor: pointer;
          box-shadow: 0px 4px 24px 0px #2a374b12;
          border: 1px solid #f5f5f5;
          margin: 8px 0;
          .tds-top-area {
            display: flex;
            gap: 24px;
            flex-direction: row;
            .tds-title-area {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              flex: 1;
              h3 {
                flex: 1 0 100%;
                .heading();
              }
              .tds-supply {
                .font-size-1a();
              }
              .tds-location {
                .font-size-1a();
              }
            }
            .quantity {
              .quantity-amount {
                .font-size-3();
                margin-bottom: 8px;
              }
              .quantity-type {
                .font-size-1a();
              }
            }
          }
          .tds-details-area {
            margin-top: 16px;
            p {
              .font-size-2();
            }
          }
        }
      }
    }
  }
}

.technical-data-sheet-form-wrap {
  padding-bottom: 0;
}
.technical-data-sheet-form {
  padding: 0 24px;
  .form-section {
    display: flex;
    flex-direction: row;
    gap: 96px;
    margin-bottom: 24px;
    .form-section-text {
      width: 300px;
      label {
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        .heading();
      }
      p {
        margin-top: 8px;
        .body();
      }
    }
    &.wide {
      flex-direction: column;
      width: 100%;
      gap: 8px;
      .form-section-text {
        text-align: center;
        width: 100%;
      }
    }
    .form-section-controls {
      flex: 1;
    }

    &.hidden {
      height: 0;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }
  .ant-form-item {
    .ant-radio-group,
    .ant-checkbox-group {
      width: 100%;

      .ant-radio-wrapper,
      .ant-checkbox-wrapper {
        display: block;
        margin-bottom: 8px;
        margin-left: 0;
        > * {
          display: inline-block;
        }
      }
    }

    .ant-radio-button-wrapper {
      width: 100%;
      padding: 16px 24px;
      text-align: center;
      height: auto;
      font-size: 14px;
      line-height: 22px;
      border-radius: 4px;
      margin-bottom: 16px;
      font-weight: 400;

      &.ant-radio-button-wrapper-checked {
        background: rgba(47, 84, 235, 0.04);
      }
    }

    .range-input-number {
      max-width: 75px;
    }

    .ant-input-group-compact .ant-form-item {
      margin-bottom: 0;
    }
  }
  .select-button-group.type-input {
    button:nth-child(-n + 6) {
      flex: 0 0 calc(33.333% - 5.333px);
    }
    button:nth-child(n + 7) {
      flex: 0 0 calc(25% - 6px);
    }
  }

  .ant-modal-footer {
    padding: 16px 0;
    border-top: 1px solid @gray-4;
  }

  .kpis {
    .ant-form-item-row {
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        label + button {
          margin-left: auto;
        }

        .switchable-component {
          flex: 100% 0 0;
        }
      }
    }
  }
}

.manage-tds-form {
  .ant-input {
    min-height: 32px;
  }
}

.color-card {
  padding: 16px;
  background: #f5f5f7;
  border-radius: 8px;
  box-shadow: 0px 2px 0px 0px #f6f7f9;
  box-sizing: border-box;

  &--poor {
    background: @red-10;
  }

  &--medium {
    background: @orange-10;
  }

  &--good {
    background: @green-10;
  }

  &--info {
    background: @blue-2;
  }
}

.profile-health {
  .color-card();
  padding-right: 24px;
  border-radius: 4px;

  h5 {
    margin-bottom: 8px;
  }

  button {
    font-weight: 600;
    font-size: 12px;
  }

  div.ant-typography {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 4px;
    max-width: 258px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &--indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #808792;
    position: relative;
    top: 1px;
  }

  &--poor {
    .profile-health--indicator {
      background: @red-5;
    }
  }

  &--medium {
    .profile-health--indicator {
      background: @gold-5;
    }
  }

  &--good {
    .profile-health--indicator {
      background: @green-5;
    }
  }

  &--info {
    .profile-health--indicator {
      background: @link-blue;
    }
  }
}

.supplier-insights {
  padding: 40px 48px;

  .ant-alert {
    margin-bottom: 16px;
  }

  .ant-tabs {
    &-nav-wrap {
      padding-left: 0;
    }

    &-content-holder {
      padding: 32px 0;
    }
  }

  .tds-selection {
    padding: 24px;
    border-radius: 6px;
    background: @blue-1;
  }

  .cohort-description {
    &--summary {
      line-height: 20px;
    }
  }

  .cohort-description {
    display: inline-block;
  }

  .cohort-description--summary {
    &--item {
      align-items: center;
      display: inline-flex;

      .anticon,
      .circular-icon {
        margin-right: 4px;
      }

      strong,
      svg {
        vertical-align: middle;
      }

      &--count {
        color: @primary-color;
      }
    }
  }

  // TODO: extract CSS that is common to suppliers and explore (belongs to components?) to a common place
  .explore-section {
    h4 {
      .section-subheading {
        color: @text-secondary;
        text-align: center;

        /* SM/Normal */
        font-family: @font-family-secondary;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
      }
    }
  }

  .ant-select.material-select {
    width: 600px;
  }
  .market-price-chart__wrapper {
    height: 290px;
  }

  .tds-price-tile {
    .warning-label {
      background: @orange-10;
      color: @orange-4;
      display: inline-block;
      padding: 2px 8px;
      border-radius: 8px;
      margin: 4px;
    }
    .ant-typography,
    .subheading {
      color: @text-secondary;
    }

    .tile-title {
      color: @text-primary;
    }

    .subheading {
      font-family: @font-family-secondary;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      margin-bottom: 72px;
    }

    .your-price {
      color: @color-gray-9;
      font-family: @font-family-secondary;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 62.5% */
    }

    .comparison-percent {
      border-radius: @border-radius-xxl;
      padding: @padding-xs @padding-sm @padding-xs @padding-xs;

      &.equal-price {
        background: @gray-2;
        color: @gray-9;
      }

      &.price-over {
        background: @red-10;
        color: @red-4;
      }

      &.price-under {
        background: @green-10;
        color: @green-7;
      }
    }

    .cohort-price {
      color: @color-gray-9;
      font-family: @font-family-secondary;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 87.5% */
    }
  }

  .pricing-section-coming-soon {
  }
}

@primary-color: #2F54EB;
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/ColorFigma.less';

.full-screen-steps {
  .ant-steps {
    margin: 0 auto;
    padding-top: 4px;
    overflow: scroll;

    .ant-steps-item-title {
      font-size: 14px;
      font-weight: 500;
    }
    .ant-steps-item-content {
      margin-top: 4px;
    }
  }

  .full-screen-modal--header {
    background: white;
    border-bottom: 1px solid #dfe3ed;
    > .ant-row > .ant-col {
      &:last-child {
        text-align: right;
      }
    }
  }
}

@import (reference) '/src/styles/CircularTypography.less';
@import (reference) '/src/styles/Color.less';

.ant-table-wrapper {
  .ant-table {
    border-radius: 6px;
    border: 1px solid @gray-5;

    .ant-table-thead > tr {
      > th.ant-table-cell,
      > td.ant-table-cell {
        background: white;
        color: #6b717a;
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        font-family: @font-family-secondary;
        white-space: nowrap;
        padding: 12px 11px;
        border-bottom: 1px solid @gray-5;

        // This is what ant uses for th borders
        &::before {
          display: none;
        }
      }

      th.ant-table-cell {
        .ant-table-filter-column {
          justify-content: flex-start;

          > .ant-table-column-title {
            flex-grow: 0;
          }
        }
      }

      &:first-of-type {
        th,
        td {
          &:first-child {
            border-radius: 6px 0 0 0;
          }
          &:last-child {
            border-radius: 0 6px 0 0;
          }
        }
      }
    }

    .ant-table-tbody > tr {
      > td.ant-table-cell {
        font-family: @font-family-secondary;
        color: @text-primary;
        padding: 14px 11px;
        font-weight: 500;
        background-color: white;
        white-space: pre-line;

        &.ant-table-cell-fix-left-last {
          border-right: 3px solid @gray-4;
          + * {
            padding-left: 24px;
          }
        }
      }
      &:last-of-type {
        th,
        td {
          border-bottom: 0;

          &:first-child {
            border-radius: 0 0 0 6px;
          }
          &:last-child {
            border-radius: 0 0 6px 0;
          }
        }
      }
    }

    .ant-table-column-sorter {
      display: none;
    }

    .ant-table-summary {
      .ant-table-cell {
        background: @gray-2;
        border-bottom: 3px solid @gray-4;
      }
    }

    .ant-empty {
      .ant-empty-footer {
        margin-top: 32px;
      }
      .ant-typography {
        max-width: 354px;
        margin: 0 auto;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
      div.ant-typography {
        font-weight: 400;
        color: #5d697b;
      }
    }
  }
  &.tds-list .ant-table {
    .ant-table-tbody > tr {
      > td.ant-table-cell {
        padding: 12px 4px;
        box-sizing: content-box;
      }
    }

    .ant-table-sticky-holder {
      top: -20px !important;
    }

    .ant-table-summary {
      td.ant-table-cell {
        padding: 12px 4px;
        box-sizing: content-box;
        font-size: 12px;
        text-align: center;
        background-color: @blue-2;
      }
    }
  }

  .search-results--table--sorter {
    display: inline-flex;
    flex-direction: column;
    vertical-align: text-top;
    margin-left: 2px;

    * {
      color: @gray-7;
      font-size: 6px;
    }

    .active * {
      color: @gray-10;
    }
  }
}

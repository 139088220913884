@import (reference) '/src/styles/Color.less';

.risk-map {
  height: 100vh;
}
.explore-map {
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  canvas {
    // Without this map overflows over border radiuses in Chromium
    border-radius: 7px;
  }

  .map-marker.black {
    svg circle {
      fill: #050c24;
    }
  }
  .map-marker-count {
    background-color: #050c24;
    color: #fff;
    height: 24px;
    min-width: 30px;
    border-radius: 12px;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25),
      0px 1px 1px 0px rgba(0, 0, 0, 0.07);
    text-align: center;
    line-height: 20px;
    padding: 2px 9px;
    overflow: visible;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--nested {
      height: 20px;
      padding: 0 9px;
      line-height: 18px;
      margin-right: -7px;
      margin-left: 5px;
      min-width: 26px;

      display: none; // We only show for Bales
    }
  }

  &.bales {
    .map-marker-count.blue {
      background-color: @link-blue;
      border: 1px solid white;

      display: block;
    }
  }
}

.map-marker-table {
  border-collapse: collapse;
  text-align: left;
  th {
    border-bottom: 1px solid @text-primary;
  }
  td {
    padding: 1px 10px;
  }
}

@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Spacing';
@import (reference) '/src/styles/Breakpoint.less';

.checkbox-card {
  padding: @size;
  border: 1px solid @cool-border-color;

  .checkbox-card--icon {
    font-size: 20px;
    color: @green-5;
    margin-top: 2px;
  }

  .checkbox-card--review-button {
    padding: 0px @size !important;
    font-size: @size;
    line-height: 24px;
    height: 40px;
  }

  @media screen and (max-width: @phone) {
    width: unset;
  }
}

.print-content {
  display: none;
}

@media print {
  @page {
    size: auto;
    margin: 16mm;
  }

  .print-content {
    display: initial;
  }

  .no-scroll {
    overflow: visible;
  }

  .print-hide,
  .simple-header-container,
  .footer-wrap,
  #landing-footer {
    display: none;
  }

  .app-layout .content {
    padding-top: 0;
  }
}

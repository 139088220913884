@import (reference) './Color.less';

@font-family-primary: Poppins, sans-serif;
@poppins: Poppins, sans-serif;
@font-family-secondary: Roboto, sans-serif;
@roboto: Roboto, sans-serif;
@Colors-Neutral-Tokens-colorTextBase: @gray-10;

.poppins {
  font-family: @font-family-primary;
}

.font-size-1 {
  font-size: 10px;
  line-height: 14px;
}

.lh-12 {
  line-height: 12px;
}

.lh-14 {
  line-height: 14px;
}

.lh-18 {
  line-height: 18px;
}

.font-size-1a {
  font-size: 11px;
  line-height: 18px;
}

.font-size-2 {
  font-size: 12px;
  line-height: 18px;
}

.font-size-3 {
  font-size: 14px;
  line-height: 22px;
}

.font-size-3a {
  font-size: 16px;
  line-height: 22px;
}

.font-size-4 {
  font-size: 18px;
  line-height: 24px;
}

.font-size-5 {
  font-size: 20px;
  line-height: 28px;
}

.font-size-6 {
  font-size: 24px;
  line-height: 32px;
}

.font-size-7 {
  font-size: 30px;
  line-height: 38px;
}

.font-size-8a {
  font-size: 36px;
  line-height: 60.5px;
}

.font-size-8 {
  font-size: 38px;
  line-height: 46px;
}

.font-size-8-b {
  font-size: 41px;
  line-height: 60px;
}

.font-size-9 {
  font-size: 46px;
  line-height: 54px;
}

.font-size-10 {
  font-size: 56px;
  line-height: 64px;
}

.body {
  .font-size-3();
  font-weight: 400;
  font-family: @font-family-primary;
  color: @text-primary;
}

.body-strong {
  .font-size-3();
  font-weight: 600;
  font-family: @font-family-primary;
  color: @text-primary;
}

.body-lg {
  .font-size-4();
  font-weight: 300;
  color: @text-primary;
}

.subtitle {
  .font-size-3();
  font-weight: 400;
  color: @gray-10;
}

.subtitle-lg {
  .font-size-4();
  font-weight: 400;
  color: @gray-10;
}

.heading {
  .font-size-5();
  font-family: @font-family-secondary;
  font-weight: 400;
  color: @gray-10;
}

.heading-lg {
  .font-size-6();
  font-family: @font-family-secondary;
  font-weight: 400;
  color: @gray-10;
}

.heading-xl {
  .font-size-7();
  font-family: @font-family-secondary;
  font-weight: 400;
  color: @gray-10;
}

.heading-2xl {
  .font-size-8();
  font-family: @font-family-secondary;
  font-weight: 500;
  color: @gray-10;
}

.heading-2xl-b {
  .font-size-8-b();
  font-family: @font-family-secondary;
  font-weight: 300;
  color: @gray-3;
}

.heading-3xl {
  .font-size-9();
  font-family: @font-family-secondary;
  font-weight: 500;
  color: @gray-10;
}

.heading-4xl {
  .font-size-10();
  font-family: @font-family-secondary;
  font-weight: 500;
  color: @gray-10;
}

.overline {
  .font-size-1();
  text-transform: uppercase;
}

.footer-heading {
  .font-size-2();
}

.caption {
  .font-size-2();
}

.button-sm {
  .font-size-2();
  text-transform: capitalize;
}

.button-md {
  .font-size-3();
  text-transform: capitalize;
}

.button-lg {
  .font-size-4();
  text-transform: capitalize;
}

.label {
  .font-size-2();
  font-family: @font-family-secondary;
  text-transform: capitalize;
}

.input-text {
  .font-size-3();
  font-family: @font-family-secondary;
  font-weight: 300;
}

.help-text {
  .font-size-2();
  font-family: @font-family-secondary;
}

.tooltip {
  font-size: var(--text-1);
}

h1.ant-typography {
  font-size: 38px;
  line-height: 46px; /* 166.667% */
}
h2.ant-typography {
  font-size: 30px;
  line-height: 38px; /* 166.667% */
}
h3.ant-typography {
  font-size: 24px;
  line-height: 32px; /* 166.667% */
}
h4.ant-typography {
  font-size: 20px;
  line-height: 28px; /* 166.667% */
}
h5.ant-typography {
  font-size: 16px;
  line-height: 24px; /* 166.667% */
}

h6.ant-typography {
  font-size: 14px;
  line-height: 22px; /* 166.667% */
}

h7.ant-typography {
  font-size: 12px;
  line-height: 20px; /* 166.667% */
}

h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  &.ant-typography {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-family: @font-family-secondary;
  }
}

.link-style {
  color: #1677ff;
  cursor: pointer;
}

.ant-typography {
  a {
    color: @Colors-Neutral-Tokens-colorTextBase !important;
    font-family: @font-family-secondary;
    text-decoration-line: underline !important;
  }
  code {
    padding: 0;
    padding-block: 0;
    padding-inline: 0;
    border: 0;
    text-transform: uppercase;
    font-size: 15px;
    background: none;
  }
  &.sm {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }
  &.lg {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 166.667% */
  }
  &.xl {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 166.667% */
  }
  &.xs {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 166.667% */
  }
  &.xxl {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
  }
  &.xxxl {
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 166.667% */
  }
  &.light {
    color: @gray-7;
  }
  &.breath {
    display: block;
  }
  &.breath-xl {
    margin-top: 32px;
    display: block;
  }
  &.breath-sm {
    margin-top: 8px;
    display: block;
  }
  &.breath-md {
    margin-top: 16px;
    display: block;
  }
  &.breath-lg {
    margin-top: 24px;
    display: block;
  }
  strong {
    font-weight: 500;
  }
  &.thin {
    font-weight: 400;
  }
  &.heavy {
    font-weight: 600;
  }
  &.upper {
    text-transform: uppercase;
  }
  &.block {
    display: block;
  }
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}

.weight-700 {
  font-weight: 700;
}

@import '../Landing.less';

#landing-footer {
  padding: 0 20px 34px;

  @media (min-width: @lg-bp) {
    padding: 0 30px 24px;
  }

  &.border-top {
    padding-top: 30px;

    .landing-footer-inner {
      border-top: 2px solid @blue-2;
    }
  }

  .landing-footer-inner {
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 60px;

    .landing-menu {
      margin-bottom: 64px;

      @media screen and (max-width: @lg-bp) {
        .landing-menu-logo-wrap {
          width: 100%;
        }

        .menu-items-inner {
          flex-direction: column;
          align-items: flex-start;
          gap: 22px;
          margin-top: 45px;
        }

        .landing-footer-legal {
          width: 100%;
        }
      }

      .logo svg {
        width: 130px;
        height: auto;
      }
      .menu-item {
        font-family: @font-family-secondary;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;

        &.sign-in {
          color: @blue-7;
          .anticon {
            font-size: 0.8em;
          }
          &:hover {
            color: @blue-6;
          }
        }
      }
    }

    .landing-footer-bottom {
      .landing-footer-legal {
        margin-right: 20px;

        @media screen and (max-width: @lg-bp) {
          width: 100%;
          margin-bottom: 14px;
        }

        > * {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: #7e889e;
        }

        a {
          text-decoration: underline;
          &:hover {
            color: @blue-6;
          }
        }
      }

      .landing-footer-social {
        margin-left: auto;

        @media screen and (max-width: @lg-bp) {
          margin-left: 0;
          margin-top: 30px;
        }

        a {
          font-size: 30px;
          color: @text-primary;
          &:hover {
            color: @blue-6;
          }
        }
      }
    }
  }
}

@primary-color: #2F54EB;
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/ColorFigma.less';

.buyerHeader {
  padding: 22px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid @cool-border-color;
}

.buyerStep {
  max-width: 70%;
  :global(.ant-steps-item-content) {
    margin: 0;
  }
}

.buyerContent {
  min-width: 879px;
}

.quantityInput {
  height: 40px;
  width: 100%;

  :global(.ant-input-number-group > .ant-input-number:first-child) {
    border-start-start-radius: @size-xs;
    border-end-start-radius: @size-xs;
  }

  :global(.ant-input-number-group > .ant-input-number-group-addon:last-child) {
    border-start-end-radius: @size-xs;
    border-end-end-radius: @size-xs;
  }
}

.propertyTitle {
  color: @text-secondary;
  font-size: @size-sm;
  font-weight: 600;
}

.inputNumber {
  border-radius: @size-xs;
  width: 100%;

  :global(.ant-input-number-handler-wrap) {
    border-start-end-radius: @size-xs;
    border-end-end-radius: @size-xs;
  }
}

@primary-color: #2F54EB;
.heatmap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
  svg {
    flex: 1 0 80%;
  }
  .details {
    padding-top: 20px;
    flex: 1 0 80px;
    margin: 0 auto;
    .visx-legend {
      width: 50px;
    }
    .cell-details {
      padding-top: 20px;
    }
  }
  .heatmap-tags {
    flex: 0 0 calc(100% - 60px);
    margin: 0;
    margin-left: 60px;
  }
}

.list-tds-unstyled {
  margin: 0;
  padding: 0 22px 0;

  li {
    padding-left: 4px;
  }
}

@primary-color: #2F54EB;
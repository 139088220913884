@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/CircularTypography.less';

.concierge-page {
  .simple-header-container {
    position: relative;
    box-shadow: none;
    border-bottom: 1px solid @gray-4;

    .gradient-border {
      height: 2px;
    }

    .simple-header {
      width: auto;
      padding: 10px 35px;

      .logo {
        height: 20px;

        &::after {
          font-size: 10px;
        }
      }

      .ant-menu .ant-menu-item {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  .app-layout .content {
    padding-top: 0;
  }

  .concierge-links {
    margin: 24px 0;
  }

  .proposal-builder {
    &--header {
      background: white;
      padding: 11px 34px;
      border-bottom: 1px solid @gray-4;
      filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.08));

      .ant-col {
        line-height: 0;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }

      .buyer-logo,
      .ant-divider {
        height: 31px;
      }

      .detail-col {
        margin-right: 45px;
        span {
          font-family: @font-family-secondary;
          letter-spacing: 0.02em;
          display: block;

          &:first-child {
            font-weight: 400;
            font-size: 10px;
            line-height: 10px;
            margin-bottom: 7px;
          }
          &:last-child {
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
          }
        }
      }

      .actions {
        margin-left: auto;
        > * {
          display: inline-block;
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
        * {
          font-weight: 600;
        }
      }
      .ant-select {
        width: 320px;
      }
    }

    &--filters {
      width: 100%;
      margin-bottom: 15px;
      font-family: @font-family-secondary;

      > .ant-row {
        width: 100%;
      }

      .left {
        display: flex;
        flex-wrap: wrap;
      }

      .right {
        > .ant-form-item {
          display: block;
          margin-right: 0;
        }

        .ant-select-selection-item {
          .anticon {
            vertical-align: middle;
          }
        }
        .ant-space-item {
          .ant-select {
            width: 100px;
          }
        }
      }

      .ant-form-item {
        margin-bottom: 10px;

        .ant-form-item-label {
          line-height: 10px;
          font-weight: 600;
          padding-bottom: 4px;
          text-align: left;

          label {
            font-size: 10px;
            line-height: 10px;
            height: auto;
          }
        }

        .ant-form-item-row {
          flex-direction: column;
        }

        .ant-select-selector,
        .range-input {
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
          min-width: 180px;
        }
      }
    }

    &--inner {
      padding: 20px 33px;
      h3 {
        margin: 0;
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
        margin-bottom: 14px;
      }

      .table-actions {
        margin-top: 24px;
        margin-bottom: 12px;
        .ant-select .anticon {
          vertical-align: middle;
        }
      }

      .evaluate {
        .mark-as {
          .ant-form-item-control {
            min-width: 150px;
          }
        }
      }
    }

    &--table {
      .ant-table {
        border-radius: 6px;
        border: 1px solid @gray-5;
        .ant-table-body tr:last-of-type td {
          border-bottom: 0;
          border-radius: 0 0 6px 6px;
        }
      }
      .eval-status-tag {
        line-height: 21px;
        height: 21px;
        text-align: center;
        border-radius: 2px;
        font-size: 12px;
        &.matched {
          color: #391085;
          background-color: #f9f0ff;
        }
        &.contacted {
          color: #0d33cc;
          background-color: #e5ebff;
        }
        &.engaged {
          color: #006d75;
          background-color: #e6fffb;
        }
        &.screened {
          color: #015089;
          background-color: #e1f3ff;
        }
        &.approved {
          color: #237804;
          background-color: #f6ffed;
        }
        &.disqualified {
          color: #a8071a;
          background-color: #fff1f0;
        }
      }

      * {
        font-family: @font-family-secondary;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.02em;
      }

      &--sorter {
        display: inline-flex;
        flex-direction: column;
        vertical-align: text-top;
        margin-left: 2px;

        * {
          color: @gray-7;
          font-size: 6px;
        }

        .active * {
          color: @gray-10;
        }
      }

      &--dropdown {
        .ant-dropdown-menu-item {
          padding: 0;
          > span {
            display: block;
            > button,
            > a {
              display: block;
              padding: 5px 12px;
              line-height: 16px;
            }
          }
        }
      }

      .ant-table-column-title,
      th {
        color: #64748d !important;
        font-weight: 400 !important;

        // This is what ant uses for th borders
        &::before {
          display: none;
        }
      }
      .ant-table-column-sorter {
        display: none;
      }
      .ant-table-cell {
        padding: 9px 8px;
        background-color: white;
        white-space: pre-line;

        // We don't have a better way to control per-column borders
        &.ant-table-cell-fix-left-last {
          border-right: 3px solid @gray-4;
          + * {
            padding-left: 24px;
          }

          + * + * + * + * {
            border-right: 1px solid @gray-4;
            + * {
              padding-left: 24px;
            }

            + * + * + * {
              border-right: 1px solid @gray-4;
              + * {
                padding-left: 24px;
              }

              + * + * {
                border-right: 1px solid @gray-4;
                + * {
                  padding-left: 24px;
                }
              }
            }
          }
        }

        &:last-child {
          border-left: 2px solid @gray-4;
          padding-left: 12px;
        }
      }
      th.ant-table-cell {
        white-space: nowrap;
      }
      .ant-table-summary {
        .ant-table-cell {
          background: @gray-2;
          border-bottom: 3px solid @gray-4;
        }
      }

      // Child rows (individual TDS rows)
      .ant-table-row-level-1 {
        .ant-table-selection-column {
          border-right: 1px solid @gray-5;
          label {
            // Don't render checkbox
            display: none;
          }
        }
      }

      .thin {
        font-weight: 400;
      }

      .subcontent {
        display: block;
        font-size: 12px;
        line-height: 12px;
        color: #64748d;
        font-weight: 400;
      }

      .company {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        a {
          color: @text-primary;
          &:hover {
            color: @link-blue;
          }
        }
      }
    }

    &--map {
      height: 600px;
      width: 100%;

      .map-marker-tooltip {
        width: 14px;
        height: 14px;
        padding: 2px;
        border-radius: 50%;
        overflow: hidden;
        background: white;
        display: block;
      }
    }
  }
}

.project-tds-indicator {
  * {
    fill: #1677ff;
  }
}

@primary-color: #2F54EB;
.chrome-extension {
  margin: 30px;
  .fixed-buttons {
    button {
      padding: 0;
      background-color: #eee;
      position: fixed;
      left: 0;
      top: 0;
    }
  }
}
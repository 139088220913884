@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/CircularTypography.less';

.background {
  background: linear-gradient(
    0deg,
    rgba(24, 70, 73, 0.05),
    rgba(24, 70, 73, 0.05)
  );
  width: 2056px;
  height: 2056px;
  border-radius: 50%;
  background-size: contain;
  left: -420px;
  position: fixed;
  top: 200px;
  z-index: -1;
}

.radio-image-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;

    position: static;
    width: 110px;
    height: 114px;
    left: 0px;
    top: 0px;

    background: #ffffff;
    /* Gray / 200 */

    border: 1px solid @gray-3;
    box-sizing: border-box;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 8px 24px 8px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    .radio-color {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      border: 1px solid @gray-3;
    }
    .commodity-symbol {
      width: 54px;
      height: 54px;
      margin: auto auto;
    }
    .commodity-symbol-outline {
      width: 54px;
      height: 54px;
      margin: auto auto;
    }

    img {
      width: 60px;
    }
    &.selected {
      border: 2px solid @primary-color;
      &:hover {
        border: 2px solid @primary-color;
      }
    }
    &:hover {
      border: 2px solid fade(@primary-color, 50%);
    }
  }
}

.ant-picker {
  width: 100%;
}

.range-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  .ant-input-affix-wrapper {
    width: 140px;
  }
  .ant-slider {
    flex-grow: 1;
  }

  &.compact {
    border: 1px solid @gray-5;
    border-radius: 4px;
    box-sizing: border-box;
    .ant-input-affix-wrapper {
      border: 0 !important;
      box-shadow: none !important;
      width: 100px !important;
      background: transparent;
    }
  }
}

.multi-select-input {
  .ant-select {
    min-width: 180px;
  }
}

.color-input {
  margin-top: 24px;
  min-height: 241px;
  .color-value {
    margin-right: 16px;
  }
  .input-help {
    .font-size-2();
    margin-bottom: 5px;
  }
  .lab-inputs {
    .ant-input-group-addon {
      width: 45px;
    }
  }
}

.select-button-group {
  gap: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &.icon {
    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 97px;
      img {
        height: 27px;
      }
      span {
      }
    }
  }
  button {
    background: @gray-2;
    border-radius: 16px;
    border: 1px solid #e4e5e9;
    &.selected {
      background: rgba(47, 84, 235, 0.08);
      border: 1px solid #2f54eb;
    }
  }
}

.multiple-location-input {
  .ant-select {
    height: auto;
  }
  // .multiple-location-input-tags {
  //   padding: 4px 0;
  //   max-width: 75%;
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   gap: 4px;
  //   .ant-tag {
  //     margin: 0;
  //   }
  // }
}

.form-button-bar {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
}

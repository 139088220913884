@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/ColorFigma.less';

.title {
  font-size: @size-sm;
  color: @Colors-Neutral_Tokens-Icon-colorIcon;
  margin-top: @size;
}

.content {
  color: @gray-10;
  line-height: 22px;
}

.space {
  height: @size-lg;
  width: @size-lg;
}

@primary-color: #2F54EB;
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Spacing';

.reset-password,
.app-content {
  .error {
    color: @danger-color;
  }
}

.reset-password {
  button {
    // make space for the "help" text on password
    margin-top: 22px;
  }
}

.signup {
  .accept-toc {
    // make space for the "help" text on password
    padding-top: 22px;
  }
}

.get-started {
  .skip-get-started {
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
  }

  .explanation-container {
    margin-top: 177px;
  }

  .go-to-container {
    margin-top: 58px;

    .go-to-buy-button {
      padding: 16px 92px;
      gap: 4px;
      width: 292px;
      height: 192px;
      background: rgba(47, 84, 235, 0.04);
      border: 1px solid #2f54eb;
      border-radius: 4px;

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .go-to-sell-button {
      padding: 16px 92px;
      gap: 4px;
      width: 292px;
      height: 192px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.magic-link-page {
  width: 100%;
  margin: 100px auto;
  background-color: #fff;
}

.magic-link-waiting-page {
  width: 100%;
  margin: 100px auto;
  max-width: 800px;
  text-align: center;
  background-color: #fff;
}

.auth-form {
  width: 365px;
  padding: @padding-xl;
  .error {
    text-align: center;
  }
  h3 {
    margin-bottom: @padding-xl;
    text-align: center;
  }
  button[type="submit"] {
    margin-top: 0;
    width: 100%;
  }
}

.test-lab-page {
  margin: 32px;
}

.test-lab-select {
  width: 515px;
  margin: 32px;
  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 100%; /* 20px */
  } 
}

.test-lab-create {
  border-radius: 4px;
  border: 1px solid #D9D9D9;

  box-shadow: 0px 4px 24px 0px rgba(42, 55, 75, 0.07);
  padding: 16px;
}

.test-lab-card {
  margin-top: 8px;
  &.selected {
    border: 1px solid #6C86F0;
    background: #F6F7FC;
    box-shadow: 0px 4px 24px 0px rgba(14, 96, 218, 0.09);
  }
  &--details {
    flex: 1 0;
  }
  &--content {
    .turn-around {
      text-transform: uppercase;
      margin-top: 8px;
    }
    padding: 16px;
    gap: 12px;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 24px 0px rgba(42, 55, 75, 0.07);
    h4 {
      font-family: @font-family-secondary;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%; /* 16px */
      margin: 0;
    }
    .circular-icon {
      color: @primary-color;
    }

    img {
      width: 28px;
      height: 28px;
      border-radius: 14px;
      background-color: #cbd3f1;
    }
  }
}
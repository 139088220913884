@import (reference) './Size.less';

@margin: @size;
@margin-sm: @size-sm;
@margin-md: @size-md;
@margin-lg: @size-lg;
@margin-xxl: @size-xxl;
@margin-2xxl: @size-2xxl;
@margin-xl: @size-xl;
@margin-xs: @size-xs;
@margin-xxs: @size-xxs;

@padding: @size;
@padding-sm: @size-sm;
@padding-md: @size-md;
@padding-lg: @size-lg;
@padding-xxl: @size-xxl;
@padding-2xxl: @size-2xxl;
@padding-xl: @size-xl;
@padding-xs: @size-xs;
@padding-xxs: @size-xxs;

.m-0 {
  margin: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}

.mr-auto {
  margin-right: auto !important;
}

.m {
  margin: @margin !important;
}
.my {
  margin-top: @margin !important;
  margin-bottom: @margin !important;
}
.mx {
  margin-left: @margin !important;
  margin-right: @margin !important;
}
.mt {
  margin-top: @margin !important;
}
.mb {
  margin-bottom: @margin !important;
}
.ml {
  margin-left: @margin !important;
}
.mr {
  margin-right: @margin !important;
}

.m-2xxl {
  margin: @margin-2xxl !important;
}
.my-2xxl {
  margin-top: @margin-2xxl !important;
  margin-bottom: @margin-2xxl !important;
}
.mx-2xxl {
  margin-left: @margin-2xxl !important;
  margin-right: @margin-2xxl !important;
}
.mt-2xxl {
  margin-top: @margin-2xxl !important;
}
.mb-2xxl {
  margin-bottom: @margin-2xxl !important;
}
.ml-2xxl {
  margin-left: @margin-2xxl !important;
}
.mr-2xxl {
  margin-right: @margin-2xxl !important;
}

.m-xxl {
  margin: @margin-xxl !important;
}
.my-xxl {
  margin-top: @margin-xxl !important;
  margin-bottom: @margin-xxl !important;
}
.mx-xxl {
  margin-left: @margin-xxl !important;
  margin-right: @margin-xxl !important;
}
.mt-xxl {
  margin-top: @margin-xxl !important;
}
.mb-xxl {
  margin-bottom: @margin-xxl !important;
}
.ml-xxl {
  margin-left: @margin-xxl !important;
}
.mr-xxl {
  margin-right: @margin-xxl !important;
}

.m-xl {
  margin: @margin-xl !important;
}
.my-xl {
  margin-top: @margin-xl !important;
  margin-bottom: @margin-xl !important;
}
.mx-xl {
  margin-left: @margin-xl !important;
  margin-right: @margin-xl !important;
}
.mt-xl {
  margin-top: @margin-xl !important;
}
.mb-xl {
  margin-bottom: @margin-xl !important;
}
.ml-xl {
  margin-left: @margin-xl !important;
}
.mr-xl {
  margin-right: @margin-xl !important;
}

.m-lg {
  margin: @margin-lg !important;
}
.my-lg {
  margin-top: @margin-lg !important;
  margin-bottom: @margin-lg !important;
}
.mx-lg {
  margin-left: @margin-lg !important;
  margin-right: @margin-lg !important;
}
.mt-lg {
  margin-top: @margin-lg !important;
}
.mb-lg {
  margin-bottom: @margin-lg !important;
}
.ml-lg {
  margin-left: @margin-lg !important;
}
.mr-lg {
  margin-right: @margin-lg !important;
}

.m-md {
  margin: @margin-md !important;
}
.my-md {
  margin-top: @margin-md !important;
  margin-bottom: @margin-md !important;
}
.mx-md {
  margin-left: @margin-md !important;
  margin-right: @margin-md !important;
}
.mt-md {
  margin-top: @margin-md !important;
}
.mb-md {
  margin-bottom: @margin-md !important;
}
.ml-md {
  margin-left: @margin-md !important;
}
.mr-md {
  margin-right: @margin-md !important;
}

.m-sm {
  margin: @margin-sm !important;
}
.my-sm {
  margin-top: @margin-sm !important;
  margin-bottom: @margin-sm !important;
}
.mx-sm {
  margin-left: @margin-sm !important;
  margin-right: @margin-sm !important;
}
.mt-sm {
  margin-top: @margin-sm !important;
}
.mb-sm {
  margin-bottom: @margin-sm !important;
}
.ml-sm {
  margin-left: @margin-sm !important;
}
.mr-sm {
  margin-right: @margin-sm !important;
}

.m-xs {
  margin: @margin-xs !important;
}
.my-xs {
  margin-top: @margin-xs !important;
  margin-bottom: @margin-xs !important;
}
.mx-xs {
  margin-left: @margin-xs !important;
  margin-right: @margin-xs !important;
}
.mt-xs {
  margin-top: @margin-xs !important;
}
.mb-xs {
  margin-bottom: @margin-xs !important;
}
.ml-xs {
  margin-left: @margin-xs !important;
}
.mr-xs {
  margin-right: @margin-xs !important;
}

.m-xxs {
  margin: @margin-xxs !important;
}
.my-xxs {
  margin-top: @margin-xxs !important;
  margin-bottom: @margin-xxs !important;
}
.mx-xxs {
  margin-left: @margin-xxs !important;
  margin-right: @margin-xxs !important;
}
.mt-xxs {
  margin-top: @margin-xxs !important;
}
.mb-xxs {
  margin-bottom: @margin-xxs !important;
}
.ml-xxs {
  margin-left: @margin-xxs !important;
}
.mr-xxs {
  margin-right: @margin-xxs !important;
}

.p-0 {
  padding: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}

.p {
  padding: @padding !important;
}
.py {
  padding-top: @padding !important;
  padding-bottom: @padding !important;
}
.px {
  padding-left: @padding !important;
  padding-right: @padding !important;
}
.pt {
  padding-top: @padding !important;
}
.pb {
  padding-bottom: @padding !important;
}
.pl {
  padding-left: @padding !important;
}
.pr {
  padding-right: @padding !important;
}

.p-2xxl {
  padding: @padding-2xxl !important;
}
.py-2xxl {
  padding-top: @padding-2xxl !important;
  padding-bottom: @padding-2xxl !important;
}
.px-2xxl {
  padding-left: @padding-2xxl !important;
  padding-right: @padding-2xxl !important;
}
.pt-2xxl {
  padding-top: @padding-2xxl !important;
}
.pb-2xxl {
  padding-bottom: @padding-2xxl !important;
}
.pl-2xxl {
  padding-left: @padding-2xxl !important;
}
.pr-2xxl {
  padding-right: @padding-2xxl !important;
}

.p-xxl {
  padding: @padding-xxl !important;
}
.py-xxl {
  padding-top: @padding-xxl !important;
  padding-bottom: @padding-xxl !important;
}
.px-xxl {
  padding-left: @padding-xxl !important;
  padding-right: @padding-xxl !important;
}
.pt-xxl {
  padding-top: @padding-xxl !important;
}
.pb-xxl {
  padding-bottom: @padding-xxl !important;
}
.pl-xxl {
  padding-left: @padding-xxl !important;
}
.pr-xxl {
  padding-right: @padding-xxl !important;
}

.p-xl {
  padding: @padding-xl !important;
}
.py-xl {
  padding-top: @padding-xl !important;
  padding-bottom: @padding-xl !important;
}
.px-xl {
  padding-left: @padding-xl !important;
  padding-right: @padding-xl !important;
}
.pt-xl {
  padding-top: @padding-xl !important;
}
.pb-xl {
  padding-bottom: @padding-xl !important;
}
.pl-xl {
  padding-left: @padding-xl !important;
}
.pr-xl {
  padding-right: @padding-xl !important;
}

.p-lg {
  padding: @padding-lg !important;
}
.py-lg {
  padding-top: @padding-lg !important;
  padding-bottom: @padding-lg !important;
}
.px-lg {
  padding-left: @padding-lg !important;
  padding-right: @padding-lg !important;
}
.pt-lg {
  padding-top: @padding-lg !important;
}
.pb-lg {
  padding-bottom: @padding-lg !important;
}
.pl-lg {
  padding-left: @padding-lg !important;
}
.pr-lg {
  padding-right: @padding-lg !important;
}

.p-md {
  padding: @padding-md !important;
}
.py-md {
  padding-top: @padding-md !important;
  padding-bottom: @padding-md !important;
}
.px-md {
  padding-left: @padding-md !important;
  padding-right: @padding-md !important;
}
.pt-md {
  padding-top: @padding-md !important;
}
.pb-md {
  padding-bottom: @padding-md !important;
}
.pl-md {
  padding-left: @padding-md !important;
}
.pr-md {
  padding-right: @padding-md !important;
}

.p-sm {
  padding: @padding-sm !important;
}
.py-sm {
  padding-top: @padding-sm !important;
  padding-bottom: @padding-sm !important;
}
.px-sm {
  padding-left: @padding-sm !important;
  padding-right: @padding-sm !important;
}
.pt-sm {
  padding-top: @padding-sm !important;
}
.pb-sm {
  padding-bottom: @padding-sm !important;
}
.pl-sm {
  padding-left: @padding-sm !important;
}
.pr-sm {
  padding-right: @padding-sm !important;
}

.p-xs {
  padding: @padding-xs !important;
}
.py-xs {
  padding-top: @padding-xs !important;
  padding-bottom: @padding-xs !important;
}
.px-xs {
  padding-left: @padding-xs !important;
  padding-right: @padding-xs !important;
}
.pt-xs {
  padding-top: @padding-xs !important;
}
.pb-xs {
  padding-bottom: @padding-xs !important;
}
.pl-xs {
  padding-left: @padding-xs !important;
}
.pr-xs {
  padding-right: @padding-xs !important;
}

.p-xxs {
  padding: @padding-xxs !important;
}
.py-xxs {
  padding-top: @padding-xxs !important;
  padding-bottom: @padding-xxs !important;
}
.px-xxs {
  padding-left: @padding-xxs !important;
  padding-right: @padding-xxs !important;
}
.pt-xxs {
  padding-top: @padding-xxs !important;
}
.pb-xxs {
  padding-bottom: @padding-xxs !important;
}
.pl-xxs {
  padding-left: @padding-xxs !important;
}
.pr-xxs {
  padding-right: @padding-xxs !important;
}

.inline-block {
  display: inline-block;
}

.mt-42 {
  margin-top: 42px;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-60 {
  margin-top: 60px;
}

@import (reference) '/src/styles/CircularTypography.less';
@import (reference) '/src/styles/Color.less';

.markets {
  &--header {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 16px;
  }
  &--time-scale {
    font-family: @font-family-secondary;
    font-weight: 500;
    span {
      display: inline-block;
      margin-right: 6px;
    }
    button {
      font-family: @font-family-secondary;
      font-weight: 500;
      background: none;
      border: 0;
      color: #979fac;
      padding-left: 0;
      padding-right: 0;
      margin-left: 18px;
      cursor: pointer;

      &:hover {
        color: @text-primary;
      }

      &.active {
        color: @text-primary;
        border-bottom: 1.5px solid @blue-7;
      }
    }
  }
  &--body {
    margin-top: 24px;
  }
}

@primary-color: #2F54EB;
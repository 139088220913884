@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/CircularTypography.less';
@import (reference) '/src/styles/Breakpoint.less';

.landing-page {
  font-size: 18px;
  font-family: @font-family-primary;
  color: @text-primary;
  font-weight: 400;
  background-color: #fff;

  * {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
    box-sizing: border-box;
  }

  p {
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: @font-family-secondary;
    font-weight: 300;
    color: inherit;
    margin: 0;
  }

  h2 {
    font-size: 34px;
    line-height: 44px;

    @media screen and (min-width: @sm-bp) {
      font-size: 36px;
      line-height: 48px;
    }

    @media screen and (min-width: @md-bp) {
      font-size: 42px;
      line-height: 56px;
    }
  }

  .pre-title {
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
}

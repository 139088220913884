.form-page-layout {
  height: 100%;
  // background: #000;
  .sidebar {
    min-height: 900px;
    position: relative;
    .fpl-background {
      width: 100%;
    }
    padding: 76px 0 0 80px;
    p {
      .heading-2xl-b();
      padding-top: 56px;
      width: 391px;
    }
  }
  h3 {
    .heading-xl();
  }

  form {
    h3 {
      padding: 67px 0 38px 0;
    }
  }
  .navigate-back {
    padding: 56px 0 0 72px;
  }
}

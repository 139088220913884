@import (reference) '/src/styles/CircularTypography.less';

.onboarding-portal {
  width: 600px;
  margin: 120px auto 60px auto;
  font-weight: 400;
  .top-logo {
    height: 72px;
    img {
      height: 48px;
      margin: 0 auto;
      display: block;
    }
  }
  ul {
    padding-inline-start: 16px;
    li {
      margin-top: 24px;
      &::marker {
        font-size: 11px;
      }
    }
  }
  .kickoff-call-details {
    margin: 20px 0 56px 0;
    min-height: 96px;
    border-radius: 2px;
    border: 1px solid #D9D9D9;
    line-height: 21px;
    padding: 24px;
    .minutes {
      line-height: 32px;
    }
    .icon-style {
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background: rgba(47, 84, 235, 0.08);
      color: @primary-color;
      .anticon {
        padding: 8px;
      }
    }
    .timezone {
      color: #64748D;
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
    }
  }
  section {
    margin-top: 40px;
    border-radius: 2px;
    /* TODO: Update to use color from Color.less */
    border: 1px solid var(--gray-gray-5, #D9D9D9);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    padding: 14px 40px 50px 40px;
  }
  h2 {
    font-family: @font-family-secondary;
    font-size: 32px;
    line-height: 38px;
    font-weight: 500;
  }
  h3 {
    font-family: @font-family-secondary;
    font-size: 26px;
    line-height: 38px;
    font-weight: 500;
  }
  h5 {
    font-family: @font-family-secondary;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }
  .nlist {
    td:first-child {
      width: 30px;
    }
    .nlist-header {
      td { padding-top: 24px; }
      font-family: @font-family-secondary;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
  }
  .button-bar {
    display: flex;
    flex-direction: row;
    .stretch { 
      flex-grow: 1;
    }
  }
  .done-status {
    background-color: #F6FFED;
    border: 1px solid #95DE64;
    padding: 8px 12px;
    margin: 24px 0 40px 0;
  }
  .required {
    color: red;
    position: relative; top: -3px; font-size: 80%;
  }
  .ant-alert {
    position: fixed;
    top: 0;
    z-index: 600;
  }
  button.ant-btn-primary {
    border-radius: 2px;
    &:not(:disabled) {
      background-color: #101839;
      color: #FAFAFA;
    }
    text-transform: uppercase;
    &:hover:not(:disabled) {
      background-color: #434343;
      color: #FAFAFA;
    } 
  }
  .team-member-row {
    .ant-col {
      flex-grow: 1; // don't understand why we need this, but the input does not fill the column without it
    }
  }
  .submit-all-button {
    margin: 56px 0;
    padding: 0 16px;
    height: 54px;
  }
  .error-section {
    color: @red-primary;
  }
  [data-id="branding"]  {
    display:none;
  }
}
@primary-color: #2F54EB;
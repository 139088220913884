@import '../Landing.less';

#company-hero {
  padding: 180px 40px 3px;
  position: relative;
  overflow: hidden;
  text-align: center;
  background: linear-gradient(
      209.28deg,
      rgba(244, 251, 255, 0.9409) 11.72%,
      rgba(245, 233, 248, 0) 38.26%,
      rgba(255, 255, 255, 0) 38.26%
    ),
    linear-gradient(
      211.76deg,
      rgba(255, 255, 255, 0.8) 15.95%,
      rgba(245, 233, 248, 0) 42.34%,
      rgba(255, 255, 255, 0) 42.34%
    ),
    linear-gradient(106.22deg, #f4f7fa 26.61%, #f3fafe 94.09%);

  @media screen and (min-width: @lg-bp) {
    padding-top: 230px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(
      90deg,
      #bfc519 0%,
      #00bf80 35.42%,
      #19b3c5 66.15%,
      #ae38da 100%
    );
  }

  h1 {
    margin-bottom: 200px;
    max-width: 1100px;

    font-size: 40px;
    line-height: 46px;

    @media screen and (min-width: @sm-bp) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (min-width: @md-bp) {
      font-size: 56px;
      line-height: 64px;
      margin-bottom: 300px;
    }

    @media screen and (min-width: @lg-bp) {
      font-size: 64px;
      line-height: 72px;
    }
  }

  .background-svg {
    position: absolute;
    right: calc(50% - 600px);
    top: calc(100% - 150px);
    // animation: rotate 200s normal linear infinite;
    svg {
      width: 1200px;
      height: 1200px;
    }

    // @media screen and (min-width: @sm-bp) {
    //   top: 400px;
    // }

    @media screen and (min-width: @lg-bp) {
      right: calc(50% - 800px);
      top: 500px;
      svg {
        width: 1600px;
        height: 1600px;
      }
    }
  }
  .background-svg-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 300px;
    background: linear-gradient(
      180deg,
      rgba(244, 251, 255, 0.27) 33.5%,
      rgba(244, 251, 255, 0.72) 100%
    );
  }
}

@primary-color: #2F54EB;
@certificate-indent-level: 20px;
@highlight-color: darken(@secondary-color, 15%);
@negative-color: darken(@danger-color, 15%);

.verified-certificate {
  padding: 20px;
  // background-color: desaturate(lighten(@secondary-color, 40%), 60%);
  max-width: 1000px;
  .verified-certificate-container {
    width: 100%;
    table {
      width: 100%;
      td:first-child {
        padding-right: 20px;
        &.key-only {
          font-weight: 500;
        }
        &.array-element {
          div {
            font-size: 9px;
            line-height: 12.5px;
            width: 15px;
            height: 12px;
            margin: 5px 0;
            border-radius: 6px;
            background-color: #ddd;
            text-align: center;
          }
        }
      }
      td:nth-child(2) {
        font-weight: 600;
      }
      td.value-unit {
        .special-value-unit {
          color: @highlight-color;
        }
        .special-color {
          color: @highlight-color;
        }
      }
      td.level-1 {
        padding-left: 1 * @certificate-indent-level;
      }
      td.level-2 {
        padding-left: 2 * @certificate-indent-level;
      }
      td.level-3 {
        padding-left: 3 * @certificate-indent-level;
      }
      td.level-4 {
        padding-left: 4 * @certificate-indent-level;
      }
      td.level-5 {
        padding-left: 5 * @certificate-indent-level;
      }
      td.level-6 {
        padding-left: 6 * @certificate-indent-level;
      }
      td.level-7 {
        padding-left: 7 * @certificate-indent-level;
      }
      td.level-8 {
        padding-left: 8 * @certificate-indent-level;
      }
      tr {
        border-bottom: 1px solid rgba(0,0,0,.06);
      }
      .anticon.anticon-check-circle {
        color: @highlight-color;

      }
      .anticon.anticon-close-circle {
        color: @negative-color;
      }
    }
  }
}
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/ColorFigma.less';

.inputCard {
  padding-top: @size-sm;
  padding-bottom: @size-sm;
  border-radius: @size-xs;
  background-color: @Colors-Primatives-Neutral_Gray-3;
  margin-top: @size-sm;

  :global(.ant-form-item-control-input) {
    min-height: unset;
  }
}

.inputNumber {
  border-radius: @size-xs;
  width: 100%;

  :global(.ant-input-number-handler-wrap) {
    border-start-end-radius: @size-xs;
    border-end-end-radius: @size-xs;
  }
}

.primaryText {
  font-size: 14px;
  color: @gray-10;
  font-weight: 600;
}

.selectTestingMethods {
  :global(.ant-select-selector) {
    min-height: 32px;
    overflow: auto;
  }
}

@primary-color: #2F54EB;
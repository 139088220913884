@import (reference) '/src/styles/CircularTypography.less';
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/Spacing.less';

.template-container {
  max-width: 900px; 
 
  .wrapper {
    position: relative;
    border: 1px solid @gray-6;

    [contenteditable="true"] {
      outline: 1px dashed #007BFF;
      background-color: #f9f9f9;
      padding: 5px;
    }

    [contenteditable="true"]:focus {
      outline: 2px solid #FF5722;
      background-color: #fff3e0;
    }
    
    [data-key] {
      white-space: pre-wrap; /* Preserves spaces and breaks */
    }

    .edit-controls {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .render-zone {
      padding: 10px;
      border-radius: 5px;
      min-height: 50px;
      white-space: pre-wrap;
    }
  }
}

@primary-color: #2F54EB;
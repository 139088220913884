@import '../Landing.less';

#about {
  padding: 100px 30px 0;

  @media (min-width: @lg-bp) {
    padding-top: 130px;
  }

  .about-inner {
    max-width: 1190px;
    margin: 0 auto;
  }

  .images-grid {
    gap: 10px;
  }

  h2 {
    margin: 34px 0;

    @media (min-width: @lg-bp) {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    max-width: 624px;
    color: #555d70;
    margin-bottom: 24px;

    &:first-child {
      margin-top: 30px;
    }

    @media (min-width: @lg-bp) {
      font-size: 16px;
      line-height: 30px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  a {
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 30px;
    color: @blue-7;
  }
}

@primary-color: #2F54EB;

.splash-page.expert {
  .splash-banner {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), linear-gradient(0deg, rgba(20, 31, 52, 0.6), rgba(20, 31, 52, 0.6));
  }
  .bullet-list {
    padding-top: 58px;
    .font-size-4();
    font-family: @font-family-secondary;
    .bullet-list-item {
      padding-bottom: 36px;
    }
  }
  .sc-header {
    .heading-2xl();
    font-weight: 400;
  }
  .sc-subheader {
    .font-size-4();
    margin-top: 16px;
    margin-bottom: 42px;;
  }
  .splash-content-body {
    max-width: 600px;
    margin-left: 120px;
    margin-top: 96px;
  }
  .ant-form-item {
    margin-top: 40px;
    .ant-form-item-label label {
      font-weight: 600;
    }
    &.query-type-other {
      margin-top: 0px;
    }
  }
  .ant-checkbox-group {
    max-width: 400px;
    .ant-checkbox-wrapper {
      padding-bottom: 12px;
    }
  }
}
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';

.container {
  border-radius: @size-xs;
  border: 1px solid #dfe3ed;
  padding: @size-md;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  height: calc(100% - 40px);
}

.title {
  font-weight: 600;
  color: @gray-10;
}

@primary-color: #2F54EB;
@import '../Landing.less';

.landing-menu {
  .landing-menu-logo-wrap {
    line-height: 0;
    .logo {
      display: inline-block;
      height: 32px;
      cursor: pointer;

      svg {
        height: 100%;
        width: auto;
      }
    }
  }

  .menu-items {
    .menu-items-inner {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
  }

  .menu-item {
    display: block;
    color: @text-primary;
    margin-right: 50px;
    cursor: pointer;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: @blue-6;
    }

    &.button {
      padding: 8px 16px;
      background-color: @blue-7;
      border-radius: 2px;
      color: white;
      box-shadow: 0px 2px 34px rgba(108, 139, 182, 0.31);
      transition: background-color 0.3s;

      &:hover {
        background-color: lighten(@blue-7, 20);
      }
    }
  }

  .close,
  .hamburger {
    font-size: 22px;
  }

  &.light {
    .menu-item,
    .hamburger {
      color: white;
    }
  }

  &.mobile {
    .menu-items {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: #f0f3ff;
      overflow: hidden;
    }

    .menu-items-inner {
      flex-direction: column;
      gap: 26px;
      align-items: flex-start;
      padding: 25px 34px;
      position: relative;
    }

    .menu-item:not(.button) {
      color: @text-primary;

      &:hover {
        color: @blue-6;
      }
    }

    .close {
      position: absolute;
      top: 25px;
      right: 34px;
    }
  }
}

@primary-color: #2F54EB;
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/ColorFigma.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/Spacing.less';

.confirm-email-send {
 
  .panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: @size-lg;
    align-self: stretch;
    background-color: @blue-2;
    padding: @padding-xl @padding-lg;
    border-radius: @border-radius-lg;

    h1 {
      font-family: Roboto;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: @Components-Form-Component-labelColor;
    }
  
    p {
      color: @Components-Checkbox-Global-colorText;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}

@primary-color: #2F54EB;
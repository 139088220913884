.mnp-group-dropdown {
  overflow: scroll;
}

.numerical-property-filter {
  display: flex;
  align-items: flex-end;

  &-range {
    .ant-form-item-label {
      line-height: 10px;
      font-weight: 600;
      padding-bottom: 4px;
      text-align: left;

      label {
        font-size: 10px;
        line-height: 10px;
        height: auto;
      }
    }

    .ant-form-item-row {
      flex-direction: column;
    }

    .ant-select-selector,
    .range-input {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
      min-width: 180px;
      display: inline-flex;
      width: auto;
    }
  }

  &-allow-null {
    margin-left: 10px;
  }
}

@primary-color: #2F54EB;
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/ColorFigma.less';

.generalInformation {
  font-size: 18px;
  font-weight: 600;
  color: @color-gray-9;
}

.primaryText {
  font-size: 14px;
  color: @gray-10;
  font-weight: 600;
}

.checkboxText {
  color: @gray-10;
}

.secondaryText {
  font-size: 14px;
  color: @text-secondary;
}

.checkBoxFormItem {
  :global(.ant-form-item-control-input) {
    min-height: 25px;
  }
}

.propertyTitle {
  color: @text-secondary;
  font-size: @size-sm;
  font-weight: 600;
}

.inputCard {
  padding-top: @size-sm;
  padding-bottom: @size-sm;
  border-radius: @size-xs;
  background-color: @Colors-Primatives-Neutral_Gray-3;
  margin-top: @size-sm;

  :global(.ant-form-item-control-input) {
    min-height: unset;
  }
}

.inputNumber {
  border-radius: @size-xs;
  width: 100%;

  :global(.ant-input-number-handler-wrap) {
    border-start-end-radius: @size-xs;
    border-end-end-radius: @size-xs;
  }
}

.quantityInput {
  height: 40px;
  width: 100%;

  :global(.ant-input-number-group > .ant-input-number:first-child) {
    border-start-start-radius: @size-xs;
    border-end-start-radius: @size-xs;
  }

  :global(.ant-input-number-group > .ant-input-number-group-addon:last-child) {
    border-start-end-radius: @size-xs;
    border-end-end-radius: @size-xs;
  }
}

.inputPriceTarge {
  :global(.ant-input-group .ant-input-group-addon:last-child) {
    border-start-end-radius: @size-xs;
    border-end-end-radius: @size-xs;
  }
}

.errorText {
  color: @danger-color;
}

.selectTestingMethods {
  :global(.ant-select-selector) {
    max-height: @size-xl;
    overflow: auto;
  }
}

@primary-color: #2F54EB;
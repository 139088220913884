@import (reference) '/src/styles/Color.less';

.quote-details {
  width: 100%;
  min-height: 100vh;

  &--header {
    border-bottom: 1px solid @gray-5;

    > div {
      display: inline-block;
      height: 16px;
      vertical-align: middle;
      width: 16px;
    }

    .ant-typography {
      font-weight: 600;
    }

    .amplify-icon svg {
      width: 16px;
      height: 16px;
    }
  }

  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      padding-left: 0;
    }
  }

  .ant-tabs-content-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .quote-details-row {
    .ant-typography:not(h6) {
      font-size: 12px;
    }
  }
}

.proposal-price-summary {
  background: @blue-1;
  .proposal-price-summary-row {
    .ant-typography:not(h6) {
      font-size: 12px;
    }
  }
}

.supplier-summary {
  width: 100%;

  .ant-typography:not(.location) {
    font-weight: 600;
  }

  .ant-typography.location {
    color: @text-secondary;
    font-size: 12px;
  }

  .ant-tag {
    border-radius: 4px;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
  }
}

.quote-generals {
  > .ant-flex {
    flex: 0 0 96px;
  }
}

.check-circle-filled {
  &.success {
    color: @green-4;
  }

  &.warning {
    color: @gold-primary;
  }
}

@primary-color: #2F54EB;
@import (reference) '/src/styles/Color.less';


.header {
  // margin: 71px auto 0 auto;
  background-color: @color-gray-9;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left:101px;  
  background: #333;
  padding: 2px 0 2px 0;
  color: #fff;
  width: 100%;
  position: fixed;
  z-index: 100;
  .hamburger {
    color: #fff;
    margin: 0 16px;
    position: relative;
    font-size: 21px;
    &.badge::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: @badge-color;
      position: absolute;
      left: 10px;
      top: -3px;
    }
  }
  .ant-menu {
    align-self: flex-end; // align to bottom of parent
    &.ant-menu-horizontal {
      justify-content: flex-end;
      @media(min-width: 1350px) {
        margin-right: 120px;
      }    
    }
    width: calc(100% - 250px);
    .ant-menu-item {
      &.ant-menu-item-selected::after {
        border-color: @secondary-color;
      }
      &.ant-menu-item-danger::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 5px;
        background-color: @badge-color;
        position: absolute;
        left: 30px;
        top: 10px;
      }
      &:hover {
        color: #fff;
        .anticon {
          color: @secondary-color;
        }
        &::after {
          border-color: @secondary-color;
        }
      }
    }
    .ant-menu-submenu-active {
      &::after {
        border-color: @secondary-color;
      }
    }
    .ant-menu-submenu-selected {
      &::after {
        border-color: @secondary-color;
      }
    }
    .ant-menu-sub {
      width: 220px;
    }
    .ant-menu-submenu {
      color: #fff;
      .ant-menu-submenu-title {
        &:hover {
          color: #fff;
          .anticon {
            color: @secondary-color;
          }
        }
      }
      .ant-menu-item {
        &:active {
          background-color: #444;
        } 
      }
      // Courtney suggested having the bottom border show when active on a submenu
      // but I couldn't get it to work
      // .ant-menu-item-active {
      //   &::after {
      //     border-color: @secondary-color;
      //     position: absolute;
      //     right: 20px;
      //     bottom: 0;
      //     left: 20px;
      //     border-bottom: 2px solid transparent;
      //     // transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      //     content: 'pop';
      //     color: red;
      //   }
      // }
      &:hover {
        color: #fff;
        .anticon {
          color: @secondary-color;
        }
        &::after {
          border-color: @secondary-color;
        }
      }
    }
    border: none;
  }
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      &.ant-dropdown-menu-item-danger::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: @badge-color;
        position: absolute;
        left: 20px;
        top: 5px;
      }
      &.ant-dropdown-menu-item-active {
        background-color: #333;
        color: #fff;
      }
    }
  }  

  button.logo {
    height: 44px;
    margin-bottom: 3px;
  }

  .headerButton {
    color: white;
    font-size: 16px; 
  }
  .loginButton {
    color: white;
    margin-right: 5em;
    font-size: 16px; 
  }

  .loginIcon {
    margin-right: 10px;
  }
  .circular-message-icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
}

.ant-dropdown-menu-submenu {
  color: #fff;
  background-color: #333 !important;
  .ant-dropdown-menu-submenu-title {
    background-color: #333 !important;
    color: #fff !important;
  }
  .ant-dropdown-menu {
    background-color: #333 !important;
    color: #fff;
    .ant-dropdown-menu-submenu-arrow-icon {
      color: #fff;
    }
    &:hover {
      color: #fff;
    }
    &.ant-dropdown-menu-submenu-active {
      .ant-dropdown-menu-submenu-title {
        color: #fff;
        background-color: #333 !important;
      }
      .menu-item {
        color: #fff;
        background-color: #333 !important;
      }
      background-color: #333 !important;
      color: #fff;
    }
  }
}


.ant-menu-submenu-popup > .ant-menu {
  .ant-menu-item-divider {
    border-color: #ccc;
  }
  .ant-menu-submenu-active {
    .anticon {
      color: @secondary-color;
    }
  }
  .ant-menu-item {
    &::after {
      border-color: @secondary-color;
    }
    &.ant-menu-item-selected::after {
      border-color: @secondary-color;
    }
    &:hover {
      .anticon {
        color: @secondary-color;
      }
      &::after {
        border-color: @secondary-color;
      }
    }
  }
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      &:hover {
        color: #fff;
        .anticon {
          color: @secondary-color;
        }
      }
    }
    color: #fff;
    &::after {
      border-color: @secondary-color;
    }
    &.ant-menu-item-selected::after {
      border-color: @secondary-color;
    }
    &:hover {
      color: #fff;
      .anticon {
        color: @secondary-color;
      }
      &::after {
        border-color: @secondary-color;
      }
    }
  }
  border-color: @secondary-color;
}
@import (reference) '/src/styles/Size.less';

.supplier-rfq--containerInfo {
  max-width: 740px;
  margin: 40px 0;
}

.supplier-rfq--steps {
  padding-top: 2px;
  .ant-steps-item-content {
    margin-top: 4px !important;
    .ant-steps-item-title {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: @phone) {
  .supplier-rfq--containerInfo {
    padding-left: @size-lg;
    padding-right: @size-lg;
  }
}

@primary-color: #2F54EB;
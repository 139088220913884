@import (reference) '/src/styles/CircularTypography.less';
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/Spacing.less';

.full-screen-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;

  background: white;
  z-index: 1000;

  &--header {
    &--title {
      font-family: @font-family-secondary;
      font-weight: 600;
      font-size: 16px;
      display: inline-block;
      margin-left: 16px;
    }

    .old-project-header {
      position: static;
      &-contents {
        padding: 9px 18px 12px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        > :first-child {
          flex-grow: 1;
          border: none;
          // height: 36px;
          width: 362px;
          &:focus {
            border: none;
            box-shadow: none;
          }
        }
        input {
          border: none;
          font-size: 16px;
          &:placeholder-shown {
            color: #979fac;
          }
        }
        .ant-divider {
          height: 36px;
          border-inline-start: 1px solid #d9d9d9;
        }
      }
    }
  }

  &--body {
    margin: 32px auto 0;
    max-width: calc(100% - 64px);

    h4.ant-typography {
      font-family: @font-family-primary;
      margin-bottom: 16px;
    }

    div.ant-typography {
      margin-bottom: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
    }

    > div.ant-typography {
      max-width: 85%;
    }

    .ant-tabs {
      padding-bottom: 40px;
      &-nav {
        margin-bottom: 24px;
      }
      &-nav-wrap {
        padding-left: 33px;
        font-family: @font-family-secondary;
        font-weight: 500;
        letter-spacing: 0.3px;
      }
      &-tab {
        padding-top: 0;
        padding-bottom: 4px;
        &-btn {
          color: #979fac;
          .anticon {
            padding-right: 4px;
          }
        }
        &.ant-tabs-tab-active {
          div.ant-tabs-tab-btn {
            color: @text-primary;
          }
        }
      }
      &-ink-bar {
        background: @primary-color;
      }
      &-content-holder {
        padding: 0 30px;
      }
    }
  }
}

@primary-color: #2F54EB;
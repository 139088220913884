
.images-grid {
  display: grid;

  > * {
    border-radius: 5px;
    overflow: hidden;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }

  .images-grid--image {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@primary-color: #2F54EB;
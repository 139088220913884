@import (reference) '/src/styles/CircularTypography.less';
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/Spacing.less';

.search-page {
  &__start {
    width: 680px;
    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 16px;
    }
    .welcome-message {
      text-align: center;
      margin: @control-height-sm @control-height-lg;
    }
    .checked-items {
      margin: 24px 141px;
      font-family: @font-family-secondary;
      font-weight: 500;
      line-height: 28px;
      .circular-icon {
        color: #389e0d;
        margin-right: 10px;
        margin-bottom: -8px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .button-container {
      display: grid;
      button {
        margin: 0 auto 12px auto;
      }
    }
  }
}

@primary-color: #2F54EB;
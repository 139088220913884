@import (reference) '/src/styles/CircularTypography.less';
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/ColorFigma.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/Spacing.less';
@import '/src/pages/project/styles/Sidebar.less';
@import '/src/components/project/procure/Procure.less';

@sidebar-width: 58px;
@cascade-width: 270px;
@explore-cascade-width: 324px;
@sidebar-width-expanded: 240px;
@explore-header-height: 83px;

.project-page {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;

  .ant-btn-link {
    &:hover {
      color: #636982;
    }
  }
}

.project-sidebar {
  display: flex;
  flex-direction: row;
  background: black;

  &-main {
    display: flex;
    flex-direction: column;

    padding: @padding-lg @padding-xs;
    // background: #101839;
    color: #fff;
    font-family: @font-family-secondary;
    overflow: overlay;
    box-sizing: border-box;
    width: @sidebar-width;
    height: 100vh;

    transition: all 0.3s;

    .circular-logo,
    .circular-logo-symbol {
      margin-left: 11px;
    }

    .ant-divider {
      border-block-start: 1px solid rgba(250, 250, 250, 0.16);
      margin: 8px 0;
    }

    .sidebar-title {
      margin-bottom: 6px;
      font-size: 11px;
      font-family: @font-family-primary;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    .sidebar-menu {
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;
      gap: 6px;
      height: 100%;

      &.project-menu {
        max-height: calc(100vh - 500px);
        overflow-y: auto;
      }

      .sidebar-menu-item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        height: 25px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 500;
        padding: 6px 0;

        &-admin {
          margin-top: auto;
        }

        &.selected {
          background-color: #1e2226;
          border-radius: 4px;
        }

        &.disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        .circular-icon {
          min-width: 20px;
          max-width: 20px;
          text-align: center;
        }

        .tag {
          background: #fa541c;
          height: 16px;
          text-transform: uppercase;
          font-size: 11px;
          letter-spacing: 0.8px;
          padding: 2px 6px;
          border-radius: 2px;
          margin: 2px 0;
          line-height: 16px;
        }

        .name {
          text-overflow: ellipsis;
          width: 155px;
          overflow: hidden;
          white-space: nowrap;
          font-family: @font-family-primary;
          font-size: 14px;
          letter-spacing: 0.6px;
          padding: 0 8px;
        }

        button {
          color: #fff;
          height: 24px;
          padding: 0;
        }
      }

      .sidebar-menu-subitem {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 8px;
        height: 25px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 500;
        padding: 3px 8px 2px 10px;

        &.selected {
          background-color: #333b5c;
          border-radius: 2px;
          padding: 3px 8px 2px;
        }

        &.disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        .tag {
          background: #fa541c;
          height: 16px;
          text-transform: uppercase;
          font-size: 11px;
          letter-spacing: 0.8px;
          padding: 2px 6px;
          border-radius: 2px;
          margin: 2px 0;
          line-height: 16px;
        }

        .subitem {
          text-overflow: ellipsis;
          width: 155px;
          overflow: hidden;
          white-space: nowrap;
          font-family: @font-family-primary;
          font-size: 12px;
          letter-spacing: 0.6px;
          padding: 0 8px;
        }
      }
    }

    .sidebar-body {
      flex-grow: 1;
    }

    .sidebar-header {
      display: flex;
      align-items: center;
      margin-bottom: 48px;
      max-height: 32px;
      img {
        max-height: 32px;
      }

      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 0 24px;
      }
    }

    .sidebar-footer {
      padding: 8px 8px 0;
      font-size: 10px;
      line-height: 16px;

      img {
        width: 33px;
        height: 33px;
      }

      .cs-card {
        font-family: @font-family-secondary;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 0;

        .cs-rep {
          display: flex;
          flex-direction: row;
          gap: 8px;
          padding: 0 0 3px 0;
          font-size: 18px;
          align-items: center;
          max-width: 100%;

          div {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            display: flex;
            flex-direction: column;
            min-width: 0;
            flex-grow: 0;

            span {
              font-size: 12px;
              font-weight: 400;
              color: #a7b6cc;
              max-width: 100%;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .ant-avatar {
            flex-shrink: 0;
            margin-right: 0;
          }

          .circular-icon {
            margin-left: auto;
          }
        }

        .cs-details {
          .anticon {
            svg {
              fill: #64748d;
            }

            padding-right: 8px;
          }

          font-size: 14px;
          line-height: 12px;
          font-weight: 400;
        }
      }

      .expand-button {
        margin-top: 8px;
        background: #ffffff00;
        color: #ffffff;
        width: 100%;
        // border-radius: 2px;
        // border: 1px solid #64748d;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.6px;

        height: 42px;
      }
    }
  }

  &-item {
    padding: 8px 8px;
    margin-bottom: 8px;
    cursor: pointer;

    .ant-typography {
      color: #aab0bb;
      font-weight: 400;
    }
    .circular-icon {
      color: #aab0bb;
      width: 19px;
    }

    &.selected,
    &:hover {
      .ant-typography {
        color: #fff;
      }
    }

    &.disabled {
      pointer-events: none;
    }

    &.selected {
      background-color: #1e2226;
      border-radius: 4px;
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    }
  }

  &-cascade {
    min-width: @cascade-width;
    max-width: @cascade-width;
    overflow: hidden;
    box-sizing: border-box;
    max-height: 100vh;
    color: #fff;
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    background: #141619;

    > div {
      padding-left: 16px;
      padding-right: 16px;
      &:first-child {
        padding-top: 20px;
      }
      &:last-child {
        padding-bottom: 20px;
      }
    }

    .project-sidebar-step {
      display: flex;
      align-items: center;
      font-weight: 400;

      .circular-icon {
        width: 19px;
      }
    }
    &--back {
      color: #6b717a;
      button {
        font-weight: 600;
        font-size: 12px;
      }
    }
    &--title {
      .ant-typography {
        color: #fff;
      }
      &:first-child {
        padding-top: 24px;
      }
    }
    &--search {
      margin-bottom: -8px;
      input {
        background: black;
        color: #fff;
        border: 0;
        padding: 3px 9px;
        &::placeholder {
          color: #595f67;
        }
        &:focus,
        &:focus-within,
        &:hover {
          background: black;
          color: #fff;
        }
      }
    }
    &--items {
      overflow-y: scroll;
      padding: 24px 0 16px;
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      scrollbar-width: none; /* Firefox */
    }

    &--sub-items {
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      scrollbar-width: none; /* Firefox */
      border-left: solid;
      border-color: #aab0bb;
      border-width: 0.85px;
      margin-left: 16px;

      > .project-sidebar-item {
        margin-left: 8px;
      }

      display: none;

      &.open {
        display: block;
      }
    }

    &.light {
      color: @gray-10;
      background: #f2f2f2;
      border-right: 1px solid @cool-border-color;

      .ant-typography {
        color: @gray-10;
        &.ant-typography-secondary {
          color: #6b717a;
        }
      }
      .project-sidebar-cascade--back,
      .project-sidebar-cascade--title {
        background: #fff;
      }
      .project-sidebar-cascade--title {
        border-bottom: 1px solid @cool-border-color;
        padding-bottom: 16px;
      }
      .project-sidebar-item {
        .project-sidebar-step {
          display: flex;
          align-items: center;

          .ant-typography {
            color: @cool-gray-7;
          }
          .circular-icon {
            width: 19px;
          }

          + .anticon {
            color: #aab0bb;
          }
        }

        &.selected,
        &:hover {
          .project-sidebar-step .ant-typography {
            color: @text-primary;
          }
        }

        &.selected {
          background-color: #fff;
        }
      }
    }
  }

  .project-sidebar-cascade {
    position: fixed;
    z-index: 10;
    left: @sidebar-width;
    top: 0;
    bottom: 0;
  }

  &:not(.expanded) {
    .project-sidebar-main {
      .circular-logo,
      .cs-name,
      .sidebar-menu-item .name,
      .sidebar-menu-item button,
      .sidebar-menu-subitem .subitem,
      .sidebar-menu-subitem .tag {
        display: none !important;
      }

      .cs-rep {
        flex-direction: column !important;

        .circular-icon {
          margin-left: 0 !important;
          margin-top: 8px;
        }
      }

      .user-avatar {
        background-color: @orange-primary;
        color: @white;
      }
    }
  }

  &.expanded {
    .project-sidebar-main {
      width: @sidebar-width-expanded;

      .user-avatar {
        background-color: @orange-primary;
        color: @white;
      }
      .circular-logo,
      .cs-name,
      .sidebar-menu-item .name,
      .sidebar-menu-item button {
        display: initial;
      }

      .circular-logo-symbol {
        display: none;
      }

      .sidebar-footer {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;

        .cs-card {
          // This is needed for the width to not overflow
          // https://bugzilla.mozilla.org/show_bug.cgi?id=1086218#c4
          min-width: 0;
        }
        .expand-button {
          margin-top: 0;
          margin-left: 8px;
        }
      }
    }

    + .project-page-contents {
      min-width: calc(100% - @sidebar-width-expanded) !important;
      max-width: calc(100% - @sidebar-width-expanded) !important;
    }

    .project-sidebar-cascade {
      left: @sidebar-width-expanded;
    }
  }
}

.project-page-contents {
  // margin-left: @sidebar-width;
  margin-top: 0;
  flex-grow: 1;
  min-width: calc(100% - @sidebar-width);
  max-width: calc(100% - @sidebar-width);
  box-sizing: border-box;

  .project-source {
    padding-right: 24px;
    &--sidebar {
      flex: 0 0 @cascade-width;
      .project-sidebar-cascade {
        min-height: 100%;
      }
    }
    &--content {
      flex: 1;
      padding: 0 24px;
    }
  }

  .project-explore {
    padding-right: 24px;
    &--sidebar {
      flex: 0 0 @explore-cascade-width;
      .project-sidebar-cascade {
        min-height: 100%;
      }
    }
    &--content {
      flex: 1;
      padding: 0 24px;
    }
  }

  .ant-tabs {
    padding-bottom: 40px;

    &-nav-wrap {
      padding-left: 33px;
      font-family: @font-family-secondary;
      font-weight: 500;
      letter-spacing: 0.3px;
    }

    &-tab {
      padding-top: 0;

      &-btn {
        color: #979fac;

        .anticon {
          padding-right: 4px;
        }
      }
    }

    &.ant-tabs-tab-active {
      &-btn {
        color: @text-primary;
      }
    }

    &-ink-bar {
      background: @primary-color;
    }

    &-content-holder {
      padding: 0 30px;
    }
  }
}

.project-page__project-create {
  .project-status {
    margin: 100px 104px;
    text-align: center;

    .kickoff-call-details {
      text-align: left;
      margin: 20px 0 56px 0;
      min-height: 96px;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      line-height: 21px;
      padding: 24px;

      .minutes {
        line-height: 32px;
      }

      .icon-style {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: rgba(47, 84, 235, 0.08);
        color: @primary-color;

        .anticon {
          padding: 8px;
        }
      }

      .timezone {
        color: #64748d;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
      }
    }

    button {
      width: 127px;
      height: 56px;
    }
  }
}

.project-header {
  padding: 32px 24px 32px 0;
  margin: 0;
  // height: @explore-header-height;

  + .ant-row {
    padding: 0 14px;
    height: calc(100vh - @explore-header-height);
    overflow: hidden;
    > .ant-col {
      padding: 20px 0;
      height: 100%;
      overflow: scroll;
    }
  }
}

.insights-bar {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 16px 34px;
  border: 1px solid #d9d9d9;
  height: 46px;

  .ant-space-item {
    font-weight: 600;
  }

  .analytics-price {
    padding: 0;
    height: 100%;
    flex: 0 0 160px;

    .price-title {
      font-size: 11px;
      letter-spacing: 0.2px;
    }

    .price-details {
      font-size: 18px;
      font-weight: 400;
      gap: 12px;
    }
  }

  .ant-divider {
    height: 100%;
    border-inline-start: 1px solid #d9d9d9;
  }
}

.project-details {
  .ant-btn {
    font-family: @font-family-primary;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    font-weight: 500;
    box-shadow: none;
    border-radius: 2px;
  }

  .circular-icon,
  .color-icon {
    line-height: 0;
    display: inline-block;
    margin-right: 4px;

    * {
      fill: @text-primary;
    }
  }

  .color-icon {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 2px;
    /* TODO: Update to use color from Color.less */
    border: 0.5px solid var(--text-primary, #323640);
  }

  &--top {
    padding: 30px 30px 0;

    h1 {
      font-size: 28px;
      line-height: 28px;
      font-weight: 400;
      margin: 0 0 12px;

      .demo-badge {
        display: inline-block;
        vertical-align: middle;
        background-color: #fa541c;
        color: #ffffff;
        font-size: 8px;
        line-height: 16px;
        text-transform: uppercase;
        padding: 2px 8px;
      }
    }

    &--members {
      margin-left: 12px;
    }
  }

  &--body {
    margin-top: 50px;

    h3 {
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
    }

    .ant-tabs {
      &-content-holder {
        h2 {
          margin: 0;
          font-size: 20px;
          line-height: 20px;
          font-weight: 500;
        }

        .tab--title {
          margin-top: 20px;
          margin-bottom: 45px;
        }
      }
    }

    .tab--actions {
      .ant-btn + .ant-btn,
      a + a {
        margin-left: 8px;
      }
    }

    .ant-table {
      th.ant-table-cell {
        background: white;
        color: #64748d;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        font-family: @font-family-secondary;
        padding: 0 0 12px;

        &::before {
          display: none;
        }
      }

      td.ant-table-cell {
        font-family: @font-family-secondary;
        padding: 12px 0;
        color: @text-primary;
      }
    }

    .ant-card {
      padding: 25px 20px 30px;
      border: 1px solid @gray-5;
      border-radius: 4px;

      .ant-card-body {
        padding: 0;
      }

      h3 {
        margin-top: 0;
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
      }

      h4 {
        font-family: @font-family-primary;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        letter-spacing: 0.2px;
        color: black;
        margin-top: 0;
      }

      p {
        color: @text-secondary;
        margin: 0;
      }

      * {
        font-family: @font-family-secondary;
      }

      strong {
        color: @text-primary;
      }

      .thin {
        font-weight: 400;
      }

      .tasks {
        margin-top: 43px;

        ol {
          padding-left: 20px;
          margin: 0;

          li {
            color: @text-primary;
            line-height: 16px;
            margin-top: 16px;
            font-weight: 400;

            &.done {
              color: @text-secondary;
            }

            > div {
              display: flex;
              justify-content: space-between;
            }

            .step-status {
              font-family: @font-family-primary;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 500;
              line-height: inherit;
              white-space: nowrap;

              &.done {
                color: @green-4;
              }

              &.in-progress {
                color: #6c86f0;
              }

              &.pending {
                color: #979fac;
              }
            }
          }
        }
      }

      &.dark {
        background: linear-gradient(288deg, #001156 8.49%, #101839 58.91%);
        border: 0;

        * {
          color: white;
        }

        p {
          color: #cbd5e4;
          font-weight: 400;
        }

        .circular-icon {
          vertical-align: middle;

          * {
            fill: @green-primary;
          }
        }

        .ant-btn-primary {
          background: white;
          border: 0;

          * {
            color: #323640;
          }

          &:hover {
            background: @blue-2;
          }
        }
      }

      // Progress "sub-card"
      .ant-card {
        padding: 0;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .ant-card-head {
          min-height: 0;
          padding: 12px;
        }

        .ant-card-head-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;

          .thin {
            color: #979fac;
          }
        }

        .tasks {
          margin-top: 0;
        }

        .ant-card-body {
          padding: 0 12px 16px;
        }
      }
    }

    .search-highlights {
      padding: 15px 13px 12px;

      .ant-col {
        &:first-child {
          margin-right: -15px;
        }

        &:last-child {
          margin-left: 15px;
        }

        &:not(:first-child) {
          border-left: 1px solid @gray-4;
          padding-left: 20px;
        }
      }

      &-value {
        font-size: 34px;
        line-height: 46px;
        letter-spacing: 0.42px;
        font-weight: 300;
      }

      &-extra {
        font-size: 12px;
        color: @text-secondary;
        font-weight: 400;
        letter-spacing: 0.24px;
      }

      .thin {
        font-weight: 300;
      }
    }

    .overview-tab {
      h2 {
        font-size: 20px;
        margin-bottom: 24px;
      }

      .up-next-action {
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, 0.22);
        background: rgba(255, 255, 255, 0.04);
        padding: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        margin-top: 12px;

        strong {
          font-weight: 500;
        }
      }

      .ant-btn-link {
        padding: 0;
        font-weight: 400;
        border: 0;
        height: auto;
        font-size: 15px;

        * {
          color: #6ab2ff;
        }
      }

      .project-progress-bar {
        margin-bottom: 33px;
      }

      .project-activity {
        margin-top: 59px;
        width: 596px;
      }
    }

    .procure-tab,
    .manage-tab {
      h2 {
        margin-top: 20px;
        margin-bottom: 24px;
      }
    }

    .manage-tab {
      .emissions-bar-chart {
        margin-top: 44px;
      }

      .shipment-progress-details {
        margin-top: 6px;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-family: @font-family-secondary;
        font-size: 11px;
        line-height: 12px;
        font-weight: 500;

        .thin {
          color: @text-secondary;
        }
      }

      .ant-alert {
        border: 0;
        font-weight: 500;
        padding: 12px;
        margin-bottom: 22px;

        &-warning {
          background: @yellow-primary;
        }

        .ant-btn {
          border: 0;
          background: transparent;
          padding: 0;
          height: auto;
          font-size: 12px;
          line-height: 12px;
        }
      }

      .shipment-map {
        border-top: 1px solid @gray-5;
        line-height: 0;
        // Balance out card padding to make map stretch to edges
        margin: 0 -20px -30px;
      }
    }

    .engagement {
      &-card {
        padding: 0;

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        h3 {
          font-weight: 400;
          font-size: 24px;
          line-height: 24px;
          margin: 20px 0;
        }
      }

      &-header,
      &-progress,
      &-action {
        padding: 0 20px;

        &:not(:last-child) {
          border-bottom: 1px solid @gray-5;
        }
      }

      &-header {
        padding-bottom: 15px;
      }

      &-data {
        font-family: @font-family-primary;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        font-weight: 400;
        display: flex;
        gap: 16px;

        .circular-icon {
          vertical-align: middle;
        }
      }

      &-progress {
        padding-top: 12px;
        padding-bottom: 10px;
      }

      &-action {
        padding-top: 18px;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 16px;

        .circular-icon {
          vertical-align: middle;

          * {
            fill: #389e0d;
          }
        }
      }
    }
  }
}

.project-details-sidebar {
  display: flex;
  flex-direction: row;

  h1 {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 22px */
  }

  h3 {
    font-family: @font-family-secondary;
    font-size: 14px;
    font-weight: 500;
  }

  .tag {
    border-radius: 1px;
    width: fit-content;
    /* TODO: Update to use color from Color.less */
    background: var(--Colors-Primatives-Purple-6, #722ed1);
    padding: 0 8px;
    /* TODO: Update to use color from Color.less */
    color: var(--Extra-white, #fff);
    font-size: 8px;
    font-weight: 600;
    line-height: 16px; /* 200% */
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }

  &--sidebar {
    width: 320px;
    padding: 21px;
    border-right: 1px solid #d9d9d9;
  }

  &--summary {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 60px;
  }
}

.project-procure {
  .ant-btn {
    font-family: @font-family-primary;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    font-weight: 500;
    box-shadow: none;
    border-radius: 2px;
  }

  .circular-icon,
  .test-lab-select {
    .circular-icon {
      line-height: 0;
      display: inline-block;
      margin-right: 4px;

      .circle {
        fill: @primary-color;
        color: #fff;
      }

      fill: #fff;
      color: #fff;
    }
  }

  .color-icon {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 2px;
    /* TODO: Update to use color from Color.less */
    border: 0.5px solid var(--text-primary, #323640);
  }

  &--top {
    padding: 30px 30px 0;

    h1 {
      font-size: 28px;
      line-height: 28px;
      font-weight: 400;
      margin: 0 0 12px;

      .demo-badge {
        display: inline-block;
        vertical-align: middle;
        background-color: #fa541c;
        color: #ffffff;
        font-size: 8px;
        line-height: 16px;
        text-transform: uppercase;
        padding: 2px 8px;
      }
    }

    &--members {
      margin-left: 12px;
    }
  }

  &--body {
    margin-top: 50px;
    max-width: 960px;
    width: 100%;

    h3 {
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
    }

    .ant-tabs {
      &-content-holder {
        h2 {
          margin: 0;
          font-size: 20px;
          line-height: 20px;
          font-weight: 500;
        }

        .tab--title {
          margin-top: 20px;
          margin-bottom: 45px;
        }
      }
    }

    .tab--actions {
      .ant-btn + .ant-btn,
      a + a {
        margin-left: 8px;
      }
    }

    .ant-table {
      th.ant-table-cell {
        background: white;
        color: #64748d;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        font-family: @font-family-secondary;
        padding: 0 0 12px;

        &::before {
          display: none;
        }
      }

      td.ant-table-cell {
        font-family: @font-family-secondary;
        padding: 12px 0;
        color: @text-primary;
      }
    }

    .ant-card {
      padding: 25px 20px 30px;
      border: 1px solid @gray-5;
      border-radius: 4px;

      .ant-card-body {
        padding: 0;
      }

      h3 {
        margin-top: 0;
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
      }

      h4 {
        font-family: @font-family-primary;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        letter-spacing: 0.2px;
        color: black;
        margin-top: 0;
      }

      p {
        color: @text-secondary;
        margin: 0;
      }

      * {
        font-family: @font-family-secondary;
      }

      strong {
        color: @text-primary;
      }

      .thin {
        font-weight: 400;
      }

      .tasks {
        margin-top: 43px;

        ol {
          padding-left: 20px;
          margin: 0;

          li {
            color: @text-primary;
            line-height: 16px;
            margin-top: 16px;
            font-weight: 400;

            &.done {
              color: @text-secondary;
            }

            > div {
              display: flex;
              justify-content: space-between;
            }

            .step-status {
              font-family: @font-family-primary;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 500;
              line-height: inherit;
              white-space: nowrap;

              &.done {
                color: @green-4;
              }

              &.in-progress {
                color: #6c86f0;
              }

              &.pending {
                color: #979fac;
              }
            }
          }
        }
      }

      &.dark {
        background: linear-gradient(288deg, #001156 8.49%, #101839 58.91%);
        border: 0;

        * {
          color: white;
        }

        p {
          color: #cbd5e4;
          font-weight: 400;
        }

        .circular-icon {
          vertical-align: middle;

          * {
            fill: @green-primary;
          }
        }

        .ant-btn-primary {
          background: white;
          border: 0;

          * {
            color: #323640;
          }

          &:hover {
            background: @blue-2;
          }
        }
      }

      // Progress "sub-card"
      .ant-card {
        padding: 0;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .ant-card-head {
          min-height: 0;
          padding: 12px;
        }

        .ant-card-head-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;

          .thin {
            color: #979fac;
          }
        }

        .tasks {
          margin-top: 0;
        }

        .ant-card-body {
          padding: 0 12px 16px;
        }
      }
    }

    .search-highlights {
      padding: 15px 13px 12px;

      .ant-col {
        &:first-child {
          margin-right: -15px;
        }

        &:last-child {
          margin-left: 15px;
        }

        &:not(:first-child) {
          border-left: 1px solid @gray-4;
          padding-left: 20px;
        }
      }

      &-value {
        font-size: 34px;
        line-height: 46px;
        letter-spacing: 0.42px;
        font-weight: 300;
      }

      &-extra {
        font-size: 12px;
        color: @text-secondary;
        font-weight: 400;
        letter-spacing: 0.24px;
      }

      .thin {
        font-weight: 300;
      }
    }

    .overview-tab {
      h2 {
        font-size: 20px;
        margin-bottom: 24px;
      }

      .up-next-action {
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, 0.22);
        background: rgba(255, 255, 255, 0.04);
        padding: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        margin-top: 12px;

        strong {
          font-weight: 500;
        }
      }

      .ant-btn-link {
        padding: 0;
        font-weight: 400;
        border: 0;
        height: auto;
        font-size: 15px;

        * {
          color: #6ab2ff;
        }
      }

      .project-progress-bar {
        margin-bottom: 33px;
      }

      .project-activity {
        margin-top: 59px;
        width: 596px;
      }
    }

    .procure-tab,
    .manage-tab {
      h2 {
        margin-top: 20px;
        margin-bottom: 24px;
      }
    }

    .manage-tab {
      .emissions-bar-chart {
        margin-top: 44px;
      }

      .shipment-progress-details {
        margin-top: 6px;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-family: @font-family-secondary;
        font-size: 11px;
        line-height: 12px;
        font-weight: 500;

        .thin {
          color: @text-secondary;
        }
      }

      .ant-alert {
        border: 0;
        font-weight: 500;
        padding: 12px;
        margin-bottom: 22px;

        &-warning {
          background: @yellow-primary;
        }

        .ant-btn {
          border: 0;
          background: transparent;
          padding: 0;
          height: auto;
          font-size: 12px;
          line-height: 12px;
        }
      }

      .shipment-map {
        border-top: 1px solid @gray-5;
        line-height: 0;
        // Balance out card padding to make map stretch to edges
        margin: 0 -20px -30px;
      }
    }

    .engagement {
      &-card {
        padding: 0;

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        h3 {
          font-weight: 400;
          font-size: 24px;
          line-height: 24px;
          margin: 20px 0;
        }
      }

      &-header,
      &-progress,
      &-action {
        padding: 0 20px;

        &:not(:last-child) {
          border-bottom: 1px solid @gray-5;
        }
      }

      &-header {
        padding-bottom: 15px;
      }

      &-data {
        font-family: @font-family-primary;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        font-weight: 400;
        display: flex;
        gap: 16px;

        .circular-icon {
          vertical-align: middle;
        }
      }

      &-progress {
        padding-top: 12px;
        padding-bottom: 10px;
      }

      &-action {
        padding-top: 18px;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 16px;

        .circular-icon {
          vertical-align: middle;

          * {
            fill: #389e0d;
          }
        }
      }
    }
  }
}

.project-manage {
  &--body {
    margin-top: 16px;

    .bar-progress {
      margin-top: 8px;
    }
  }

  .market-price-chart {
    &__wrapper {
      height: 291px;
    }
    &__controls {
      margin-top: -32px;
    }
  }

  .reports-tab {
    width: 992px;
  }

  .logistics-tab {
    width: 992px;

    .shipment {
      button {
        font-size: 12px;
        text-transform: uppercase;
      }

      img {
        width: 100%;
      }
    }
  }

  &--supplier-details {
    padding: 32px;

    img {
      width: 32px;
      height: 32px;
    }

    .supplier-metrics {
      margin-top: 24px;
    }
  }
}

.project-tds-activity {
  .ant-drawer-body {
    background-color: @gray-2;
    overflow: scroll;
    > * {
      overflow-anchor: none;
    }
    #anchor {
      overflow-anchor: auto;
      height: 1px;
    }
  }
  .single-item {
    border: 1px solid @cool-border-color;
    padding: 16px;
    border-radius: 6px;
    font-weight: 400;
    background-color: #fff;

    &--date {
      color: @text-secondary;
    }
  }
}

.ant-modal {
  .ant-modal-header {
    padding-bottom: 24px;
    border-bottom: 0;
    margin-bottom: 0;
  }

  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-title {
    font-family: @font-family-primary;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  form#project {
    .ant-form-item-control {
      flex-direction: column-reverse;
    }

    .ant-form-item {
      margin-bottom: 40px;
    }

    label {
      font-family: @font-family-secondary;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    p {
      margin-top: 0;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }

    .button-bar {
      margin-top: 80px;
      padding-top: 16px;
      border-top: 1px solid @gray-5;

      button {
        display: block;
        margin-left: auto;
        box-shadow: none;
        padding: 16px 44px;
        height: auto;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        font-weight: 500;
      }
    }
  }

  &.upsell-modal {
    text-align: center !important;

    .ant-modal-content {
      padding: 50px 50px 60px;
    }
  }

  &.dark {
    .ant-modal-close {
      display: none;
    }

    .ant-modal-content {
      background: linear-gradient(288deg, #001156 8.49%, #101839 58.91%);
      border: 0;
      color: white;
    }

    h2 {
      color: white;
      font-weight: 500;
      margin-top: 40px;
    }

    p {
      color: #cbd5e4;
      font-family: @font-family-secondary;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 40px;
      font-weight: 400;
    }

    .ant-btn-primary {
      background-color: white;
      border: 0;
      box-shadow: none;
      color: @text-primary;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      padding: 10px 27px;
      height: auto;
    }
  }

  .stages {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    > div {
      background: #3292fa;

      &:not(.active) {
        opacity: 0.2;
      }
    }

    .stage {
      text-transform: uppercase;
      padding: 6px 10px;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.65px;
      background: #3292fa;
      border-radius: 2px;

      &.active {
        padding: 11px 13px;
        font-size: 16px;
        letter-spacing: 0.8px;
      }
    }

    .divider {
      height: 3px;
      width: 8px;
    }
  }
}

.chat-notification-icon {
  > path {
    // This is the red dot to indicate a notification on chat icon
    fill: #f5222d !important;
  }
}

.dark-panel {
  h2 {
    text-align: left;
    margin: 0 0 12px 0;
  }

  .boxes {
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: row;

    .dark-panel-box {
      border-radius: 4px;
      padding: 20px;

      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;

      display: flex;
      flex-direction: column;

      .box-title {
        /* TODO: Update to use color from Color.less */
        color: var(--Colors-Neutral-Tokens-colorBgBase, #fff);

        font-family: @font-family-secondary;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin: 0 0 12px;
      }

      .panel-content {
        flex: 1 0 64px;
        /* TODO: Update to use color from Color.less */
        color: var(--Colors-Primatives-Circular-Blue-3, #ccd5f6);

        font-family: @font-family-secondary;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 16px;
      }
    }

    p {
      margin: 0;
    }
  }

  .recap-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 32px;

    div:first-child {
      flex-grow: 1;
    }
  }

  .rainbow-progress {
    margin: 32px 0;
  }
}

.explore-page,
.search-page {
  .content {
    h4 {
      margin-top: 0;
    }

    .ant-empty {
      margin-top: 100px;
    }
  }
}

.testing-section {
  h1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    font-family: @font-family-secondary;
    margin-top: -2px;
  }

  .testing-step-card {
    width: 840px;
    border-radius: 4px 4px;
    border: 1px solid #d9d9d9;
    margin-bottom: 16px;

    &--header {
      padding: 24px 20px;

      &.inactive {
        color: #979fac;
        cursor: default;

        h2 {
          color: #979fac;
        }
      }

      display: flex;
      cursor: pointer;
      flex-direction: row;
      gap: 16px;

      h2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        font-family: @font-family-secondary;
        flex: 1 1;
      }

      .number {
        width: 24px;
        height: 24px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        font-family: @font-family-secondary;
        display: inline-block;
        text-align: center;
      }
    }

    &--body {
      padding: 24px 20px;
      border-top: 1px solid #d9d9d9;
    }
  }
}

.supplier-compare {
  display: grid;
  grid-template-rows: 43px auto;
  grid-auto-rows: 60px;
  text-align: center;
  margin-bottom: 30px;

  &--row {
    display: contents;

    &:not(:nth-child(1)):not(:nth-child(2)) > * {
      border-style: solid;
      border-color: @gray-4 @gray-5;
      border-width: 1px 0 0 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 12px;

      &:first-child {
        border-left: 0;
        align-items: start;
        font-weight: 400;
      }
    }

    &:last-child > * {
      border-bottom-width: 1px !important;
    }
  }

  &--company-avatar {
    width: 35px;
    height: 35px;
    object-fit: contain;
    border-radius: 50%;
  }

  h5.ant-typography {
    margin-top: 8px !important;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
  }

  .subcontent {
    display: block;
    font-size: 12px;
    line-height: 12px;
    color: #64748d;
    font-weight: 400;
  }

  &.proposal {
    grid-template-rows: 43px auto 40px;

    // .supplier-compare--row:nth-child() {
    // }
  }
}

.project-task-list {
  .task-list-header {
    display: flex;
    flex-direction: row;

    h2,
    h3,
    h4,
    h5 {
      flex: 1 0;
    }
  }

  .dark-panel-box {
    display: flex;
    gap: 8px;

    .circular-icon {
      color: #666c84;
    }

    padding: 16px;
    margin-bottom: 12px;

    .task-title {
      font-size: 16px;
      font-weight: 400;
    }

    .task-subtitle {
      font-size: 16px;
      font-weight: 400;
      color: #c6cce9;

      span {
        color: @white;
        font-size: 14px;
        font-weight: 500;

        &.danger {
          color: @danger-color;
        }
      }
    }
  }
}

.ant-empty {
  h4.ant-typography {
    color: @text-primary;
    margin-bottom: @margin;
  }

  div.ant-typography {
    color: @text-secondary;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}

.how-does-it-work {
  color: @text-secondary;
  font-size: 14px;
  font-family: @font-family-secondary;
}

.chart-info-label {
  color: #323640;
  font-family: @font-family-secondary;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  border-radius: 2px;
  background: #f0f3ff;
  padding: 8px 8px;
  display: inline-block;

  .chart-info-bullet {
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #2f54eb;
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 1px;
  }
}

.axis-label {
  color: #63748d;

  font-family: @font-family-secondary;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &.right-aligned {
    text-align: right;
  }
}

.pricing-section {
  .analytics-tile-line-titles.market-price-titles {
    margin-top: @size-lg;
  }

  .box-panel {
    height: 420px;
    position: relative;

    h3 {
      margin-bottom: @size-lg;
    }
  }

  .price-savings-chart {
    &__panel {
      .price-savings-chart__wrapper {
        flex-grow: 1;
        height: 325px;
      }
    }
  }

  .price-history-chart__panel {
    .ant-select {
      width: 72px;
    }
  }
}

.bales-explore {
  .box-panel {
    height: 100%;
    box-sizing: border-box;

    .ant-row .ant-col:first-child {
      margin-right: auto;
    }
  }
}
.explore-suppliers--controls {
  margin: @size-sm 0;
  .ant-input-outlined {
    width: 320px;
  }
  .ant-input-affix-wrapper {
    max-height: 32px;
  }
  button {
    max-height: 32px;
  }
}

.explore-suppliers-table {
  display: flex;
  flex-direction: column;
  gap: @size-sm;
  .mb-lg();
  &--panel {
    &.expanded {
      box-shadow: 0px 0px 4px 4px rgba(55, 60, 109, 0.02);
    }
  }
  &--row {
    .supplier-link {
      color: @Colors-Neutral_Tokens-colorTextBase;
    }
    cursor: pointer;
    border-radius: @border-radius-lg;
    border: 1px solid @border-color;
    background: @gray-1;

    /* Component/Button/defaultShadow */
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    display: flex;
    padding: @size-sm @size @size-sm @size;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    gap: 14px;
    .result-label {
      background-color: @gray-3;
      padding: 4px 8px;
      font-weight: 600;
    }
    &.removed {
      background-color: @gray-2;
    }
    &.expanded {
      border-radius: @border-radius-lg @border-radius-lg 0 0;
    }
    &--top {
      display: flex;
      flex-direction: row;
      gap: @size-xs;
      width: 100%;
      height: 24px;
      /* FIXME not sure why i have to style the antd btn to get the text alignment working */
      button.ant-btn-sm {
        padding: 0 7px 0px 7px;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
    &--content {
      width: 100%;
      .ant-col {
        .font-size-2();
      }
    }
    &--title {
      .font-size-3();
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 600;
      align-items: flex-start;
    }
    &--header {
      font-weight: 600;
    }
    &--tds-headers {
      padding: 0 12px;
      min-width: 100%;
    }
    &--tds-content {
      .mt-sm();
      background: #ffffff;
      padding: 12px;
      border-radius: 6px;
      border: 1px solid @cool-border-color;
      min-width: 100%;

      button {
        height: 26px;
        width: 26px;
        padding: 4px 2px;
        vertical-align: middle;
      }
      span.ant-typography {
        vertical-align: middle;
      }
    }
    button.accept-button.accepted {
      background-color: @blue-2;
    }
  }
  &--count {
    font-weight: 600;
    .font-size-2();
    background: @blue-1;
    color: @gray-8;
    display: flex;
    .px-xs();
    justify-content: center;
    align-items: center;
    gap: 2px;
    align-self: stretch;
  }
  &--tds-list {
    background-color: @cool-gray-1;
    padding: @size @size-sm @size-sm @size-sm;
    border-radius: 0 0 @border-radius-lg @border-radius-lg;
    border-right: 1px solid @cool-border-color;
    border-bottom: 1px solid @cool-border-color;
    border-left: 1px solid @cool-border-color;
    overflow-x: scroll;
  }
}

.explore-suppliers-table-tag {
  .font-size-2();
  display: inline-block;
  padding: 2px 6px;
  margin-right: 8px;
  text-align: center;
  background: @gray-5;
  color: @gray-8;
  font-weight: 600;
  border-radius: 4px;

  &.normal {
    &.high {
      background: @Colors-Primatives-Polar_Green-7;
      color: @Colors-Primatives-Neutral_Gray-1;
    }
    &.low {
      background: @Colors-Primatives-Polar_Green-2;
      color: @Colors-Primatives-Polar_Green-8;
    }
    &.none {
      background: @Colors-Primatives-Polar_Green-2;
      color: @Colors-Primatives-Polar_Green-8;
      opacity: 0.2;
    }
  }
  &.alert {
    &.high {
      background: @Colors-Primatives-Purple-2;
      color: @Colors-Primatives-Purple-6;
    }
    &.none {
      background: @Colors-Primatives-Purple-2;
      color: @Colors-Primatives-Purple-6;
      opacity: 0.2;
    }
  }
  &.primary {
    background: @Colors-Brand_Tokens-Primary-colorPrimary;
    color: #fff;
  }
}

.supplier-count-narrative {
  margin: @size-sm 0;
  font-weight: 600;
  .softer {
    font-weight: 400;
  }
}

.expanded-style-text {
  color: @Colors-Primatives-Purple-6;
}

.value-input-popover {
  &--container:not(.disabled) {
    cursor: pointer;
  }
}

.value-input-form {
  .location-input {
    min-width: 300px;
  }
}

.manage-project-ellipsis {
  vertical-align: middle;
  line-height: 0;
  margin-top: 4px;
}

.market-price-chart-with-controls {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  &__chart {
    position: relative;
    min-height: 0;
    height: 100%;
  }
}
.market-price-chart {
  &__wrapper {
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    > * {
      min-height: 0;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  &__chart {
    flex: 1;
    > canvas {
      max-height: 100%;
    }
  }
  &__controls {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@primary-color: #2F54EB;
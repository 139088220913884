@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/ColorFigma.less';

.buttonSkip {
  color: @primary-color;
}

.description {
  color: @Colors-Primatives-Cool_Gray-5;
}

@primary-color: #2F54EB;
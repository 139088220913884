@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/Spacing.less';
@import (reference) '/src/styles/Color.less';

.page-container {
  padding: 15px 0 75px 0;
  width: 100%;
  background-color: #fff;
  .page-body {
    margin: 0 auto;
    @media (min-width: 1350px) {
      width: 1300px;
    }
  }
}

.app-layout {
  .content {
    .ant-breadcrumb {
      margin: 16px 0 0px 8px;
    }
    .breadcrumb-title h3 {
      font-weight: 400;
      margin-bottom: 16px;
    }
    padding-top: 72px;
    height: 100%;
  }
  height: 100%;
}

.splash-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background: white;
  z-index: 120;
  //
  //.splash-banner {
  //  height: 100vh;
  //}
  //
  & .splash-container {
    & .splash-banner {
      height: 100vh;
    }
  }
}

.no-scroll {
  overflow: hidden;
}

@portal-sidebar-width: 324px;
@portal-sidebar-padding: 80px;

.portal-page-layout {
  .sidebar {
    @media (max-width: @md-bp) {
      display: none;
    }
    z-index: 600;
    height: 100vh;
    width: @portal-sidebar-width - @portal-sidebar-padding;
    position: fixed;
    left: 0;
    top: 0;
    .fpl-background {
      width: 100%;
    }
    padding: 76px 0 0 @portal-sidebar-padding;
    p {
      .heading-2xl-b();
      padding-top: 56px;
      width: 391px;
    }
  }
  .portal-page-content {
    margin-left: @portal-sidebar-width;
  }
}

.bare-page-layout {
  .gradient-border {
    background: linear-gradient(
      90deg,
      #1340fa 0%,
      #51ce92 55.21%,
      #fbc12d 100%
    );
    height: 4px;
    width: 100%;
  }
}

.dark-panel {
  display: block;
  .border-radius-lg();
  overflow: hidden; // prevent margin collapse
  &--contents {
    margin: @control-height-sm;
  }
  .dark-panel-box {
    background: #1e233b;
    border-radius: 4px;
  }
  button {
    color: #fff;
    &.ant-btn-default {
      background-color: @dark-background;
    }
  }
}

.light-panel {
  border: 1px solid @gray-5;
  background: @white;
  .border-radius-lg();
  overflow: hidden; // prevent margin collapse
  &--contents {
    margin: @control-height-sm;
  }
  &.tight-md {
    .light-panel--contents {
      margin: @control-height-xs;
    }
  }
  &.breath-lg {
    margin-top: 24px;
  }
  &.breath-md {
    margin-top: 16px;
  }
  &.breath-sm {
    margin-top: 16px;
  }
}

.dark-panel,
.light-panel {
  h5.ant-typography {
    margin-bottom: 8px;
  }
  h4.ant-typography {
    margin-bottom: 16px;
  }
}

.dark-background {
  background-color: @dark-background;
  color: #fff;
  .ant-typography {
    color: #fff;
  }
}

.background-page {
  padding-top: 120px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  // background: @Gradients-Dark-Grad;
  height: 100vh;
  align-items: center;
  gap: 40px;
  .circular-logo {
    width: 171px;
  }
  .background-page--content {
    margin: 0 auto;
    background-color: @white;
    border-radius: @border-radius-lg;
  }
}

.old-project-header {
  // position: static;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
  background: white;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0 0 5px rgba(50, 50, 50, 0.1);

  .logo {
    display: inline-block;
    svg {
      width: 130px;
      height: auto;
    }
  }

  &-contents {
    padding: 9px 18px 12px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    > :first-child {
      flex-grow: 1;
      border: none;
      width: 362px;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    input {
      border: none;
      font-size: 16px;
      &:placeholder-shown {
        color: #979fac;
      }
    }
    .ant-divider {
      height: 36px;
      border-inline-start: 1px solid #d9d9d9;
    }
  }
}


.chat-window {

  margin: 0;
  z-index: 2147483000;
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 700px;
  max-width: min(90%, 700px);
  min-height: 0;
  max-height: 80%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
  height: 0;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  transition: opacity 0.2s ease-in, transform 0.2s ease-in;
  opacity: 0;
  transform: translateY(4px) translateZ(0px);
  pointer-events: none;

  &.open {
    transform: none;
    opacity: 1;
    min-height: 250;
    height: calc(100% - 20px);
    pointer-events: auto;
  }

  .chat-window-inner {
    display: flex;
    background: #fff;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1;

    .chat-header {
      padding: 16px;
      background: #374151;
      color: #fff;

      h2 {
        margin-top: 4px;
        margin-bottom: 4px;
        font-weight: 500;
        color: rgb(255, 255, 255);
      }

      p {
         margin: 0px;
         color: rgba(255, 255, 255, 0.8);
      }

      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 24px;
        color: #aaa;
        cursor: pointer;
        background: transparent;
        border: none;
      }
    
      .close:hover {
        color: #efe9e9;
      }
    }

    .scroll-area {
      flex: 1;
      overflow-y: scroll;
    }

    .track {
      padding: 8px;

      .message-wrapper {
        display: flex;
        justify-content: flex-end;
        margin: 8px;

        &.not-me {
          justify-content: flex-start;

          .message {
            background: rgb(245, 245, 245);
            color: rgba(0,0,0,.65);
          }
        }

        .message {
          border-radius: 4px;
          padding: 8px;
          background: #374151;
          color: #fff;
          white-space: pre-wrap;
        }
      }

      .underlined {
        border-bottom: 1px solid #ccc; /* Line style and color */
        width: calc(100%); 
        /* margin: 0 10px; */ /* Space on each end */
        padding-bottom: 4px; /* Optional: Space between content and line */
        display: inline-block; /* Allows control over width */
      }
    
      .createdAt {
        color:#aaa;
        font-size: 0.75rem;
      }
    }

    .message-capture {
      display: flex;
      align-items: center;
      /* gap: 10px;
      padding: 10px; */
      border-top: 1px solid #ccc;
      border-radius: 0 0 8px 8px;
      width: 100%;
      height: 100px;
      box-sizing: border-box;
      /* margin: 0 auto; */
    }
  
    .message-input {
      flex: 1;
      height: 100%;
      padding: 8px 12px;
      font-size: 16px;
      border-radius: 8px;
      resize: none;
      outline: none;
      box-sizing: border-box;
  
      border: none;
    }
  
    .message-input:focus {
      /*border-color: #007bff;
      box-shadow: 0 0 4px rgba(0, 123, 255, 0.5); */
    }

    .send-button {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      outline: none;
      transition: background-color 0.3s;
    }
  
    .send-button:hover {
      background-color: #0056b3;
    }
  
    .send-button:active {
      background-color: #004494;
    }
  
    .send-button svg {
      width: 20px;
      height: 20px;
    }
  
    .send-button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
      opacity: 0.6;
    }
  
    .send-button:disabled svg {
      stroke: #999;
    }
  }
}
@primary-color: #2F54EB;
@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/CircularTypography.less';
@import (reference) '/src/styles/Breakpoint.less';

.gated-report {
  .hero {
    padding: 130px 25px 140px;
    position: relative;
    overflow: hidden;
    color: @text-primary;
    font-family: @font-family-primary;

    @media screen and (min-width: @lg-bp) {
      padding: 160px 108px 240px;

      .hero-text {
        max-width: 600px;
        margin-right: 50px;
      }

      .hero-images {
        max-width: 650px;
      }

      .images-grid {
        margin-right: -120px;
      }
    }

    h1 {
      font-family: @font-family-primary;
      font-weight: 600;
      margin-top: 0px;
      margin-bottom: 40px;
      font-size: 35px;
      line-height: 40px;

      @media screen and (min-width: @sm-bp) {
        font-size: 40px;
        line-height: 50px;
      }

      @media screen and (min-width: @xl-bp) {
        font-size: 44px;
        line-height: 53px;
      }

      + p {
        line-height: 28px;
        font-size: 16px;
        font-weight: 400;
        font-family: @font-family-primary;
      }
    }

    .background-svg {
      position: absolute;
      right: -750px;
      bottom: -750px;
      opacity: 0.8;
      // animation: rotate 200s normal linear infinite;
      svg {
        width: 1100px;
        height: 1100px;
      }

      @media screen and (min-width: @lg-bp) {
        right: -550px;
        bottom: -1050px;
        svg {
          width: 1500px;
          height: 1500px;
        }
      }
    }

    .background-svg-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(98.57deg, #eff2ff 33.94%, #f8eeff 88.13%);
    }
  }

  .pre-title {
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-family: @font-family-primary;
  }

  .ant-form {
    background: white;
    border-radius: 6px;
    padding: 30px 20px 38px;
    margin-top: 30px;
    box-shadow: 0px 4px 24px 0px rgba(42, 55, 75, 0.07);

    label {
      font-weight: 600;
    }

    input {
      border-radius: 2px;
      border-color: @gray-5;
    }

    button {
      background: @blue-7;
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      height: auto;
      padding: 16px;
      border-radius: 6px;
      font-family: @font-family-primary;
    }

    .ant-alert {
      margin-bottom: 24px;
    }
  }
}

@primary-color: #2F54EB;
.procure-overview {
  .ant-table {
    .ant-table-cell:not(.ant-table-cell-fix-left) {
      text-align: center;
      > .ant-col,
      > button > .ant-col {
        margin: 0 auto;
      }
      .explore-summary--value-box {
        background-color: transparent !important;
      }
    }
  }
}

.quotes-comparison {
  .ant-divider {
    margin: 14px -12px;
    width: auto;
  }
  .ant-slider-step {
    display: none;
  }
  .ant-slider-mark {
    margin-top: 8px;
  }
  .caret-mark {
    position: relative;
    top: -30px;
    font-size: 18px;
    &.target {
      color: #52c41a;
    }
    &.index {
      color: #aab0bb;
    }
  }
  .avg-mark {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: calc(100% + 4px);
      border: 0;
      left: 50%;
      width: 3px;
      height: 8px;
      border-radius: 0;
      background: white;
      transform: translateX(-50%);
    }
  }
}

.supplier-details {
  .ant-descriptions-row {
    > th.ant-descriptions-item {
      padding-bottom: 8px;
    }
    > td.ant-descriptions-item {
      padding-bottom: 20px;
    }
    &:last-child {
      > td.ant-descriptions-item {
        padding-bottom: 0;
      }
    }
  }
}

.procure--testing--boxes {
  display: grid;
  gap: 16px;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

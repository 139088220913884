@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/CircularTypography.less';

.rfq__content {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;

  h3.ant-typography {
    font-family: @font-family-secondary;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 24px;
    font-weight: 600;
  }

  .ant-list {
    width: 100%;
    border-block-end: 1px solid @gray-6;
    .ant-list-header {
      border: 0;
    }
    .ant-list-item {
      padding-left: 0;
      padding-right: 0;
      border-color: @gray-5;
    }

    .ant-row {
      width: 100%;
      white-space: pre-line;
      .capitalize {
        text-transform: capitalize;
      }

      .ant-col:last-child:not(.ant-col-24) {
        text-align: right;
      }
    }
  }

  .ant-btn {
    margin: 50px 0;
  }
}

.rfq-modal.ant-modal {
  div.ant-modal-header {
    padding: 20px 24px;
    margin-bottom: 0;
    border: 0;
  }
  div.ant-modal-body {
    padding: 0 24px;

    .ant-list:first-child h3.ant-typography {
      margin-top: 0;
    }
  }
  div.ant-modal-footer {
    padding: 24px;
    bottom: 0;
    border-top: 1px solid @gray-6;
    .ant-btn {
      font-weight: 500;
      border-radius: 4px;
    }
  }
  .ant-modal-content {
    padding: 0;
    max-height: calc(100vh - 200px);
  }
}

#request-for-quote-view {
  .rfq__approved-banner {
    background-color: @green-primary;
    color: white;
    text-align: center;
    font-family: @font-family-secondary;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    padding: 4px 24px;
  }
  .rfq__header {
    background: #101839;
    padding: 64px 15px 35px;

    h1 {
      color: white;
      font-size: 18px;
      line-height: 38px;
      font-weight: 500;
      margin: 0;
    }

    .ant-row {
      max-width: 800px;
      margin: 0 auto;
    }

    .ant-btn {
      border-radius: 2px;
      font-size: 14px;
    }

    .logo {
      display: inline-block;

      svg {
        width: 120px;
        height: auto;
      }
    }
  }

  .rfq__content h3 {
    margin-top: 50px;
  }

  @media print {
    * {
      color: black !important;
    }

    .rfq__header {
      padding: 0;
      background: none;

      h1 {
        font-size: 13px;
        line-height: 22px;
      }
    }
    .rfq__content {
      padding: 0;

      .ant-typography {
        font-size: 13px;
      }
      h3.ant-typography {
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 0;
        page-break-after: avoid;
      }
      .ant-list-item {
        page-break-inside: avoid;
      }
    }
    .ant-btn {
      display: none;
    }
  }
}

@media print {
  .rfq__content {
    padding: 0;

    * {
      color: black !important;
    }
    .ant-typography {
      font-size: 13px;
    }
    h3.ant-typography {
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 0;
      page-break-after: avoid;
    }
    .ant-list-item {
      page-break-inside: avoid;
    }
  }
  .ant-btn {
    display: none;
  }
}

.insights-page {
  margin-top: 30px;
  h1 {
    font-size: 28px;
    font-weight: 600;
    font-family: @font-family-primary;
    line-height: 28px;
    margin: 0 30px;
  }
  h2 {
    font-size: 20px;
    font-weight: 500;
    font-family: @font-family-secondary;
    line-height: 28px;
    margin: 8px 0;
  }
  small {
    font-size: 12px;
    font-weight: 400;
    font-family: @font-family-secondary;
    line-height: 12px;
  }
  .ant-tabs {
    margin-top: 44px;
  }
  table {
    margin-bottom: 32px;
    thead {
      th,
      td {
        color: #64748d !important;
        font-family: @font-family-secondary;
        font-size: 14px;
        font-weight: 400 !important;
        background-color: #ffffff !important;
        text-align: right !important;
      }
    }
    tbody {
      td.region-column {
        background-color: #fafafa;
        font-weight: 400 !important;
        text-align: center;
      }
      td.row-header {
        text-align: left;
        font-size: 14px;
        font-weight: 400 !important;
      }
      td {
        font-family: @font-family-secondary;
        font-size: 16px;
        font-weight: 500 !important;
        text-align: right;
      }
    }
  }
  .supplier-analytics-region {
    .ant-select {
      width: 180px;
      margin-left: 32px;
    }
  }
  .ant-tabs-nav-list {
    .circular-icon {
      margin: 0 6px;
    }
  }
  .insights-news {
    a {
      color: @text-primary;
    }
    .markdown {
      a {
        color: #1677ff;
      }
    }
    display: flex;
    flex-direction: row;
    max-width: 1100px;
    gap: 40px;
    .insights-news--current-bar {
      display: flex;
      flex-direction: column;
      flex: 1 0 320px;
    }
    .insights-news--previous-bar {
      flex: 0 1 320px;
      h2 {
        margin-bottom: 40px;
      }
    }
    .insights-news--current {
      margin-top: 24px;
      .ant-divider {
        margin: 40px 0;
      }
    }
    .insights-news--current-story {
      display: flex;
      flex-direction: row;
      .story-index {
        font-family: @font-family-secondary;
        font-size: 48px;
        line-height: 48px;
        font-weight: 300px;
        flex: 0 0 108px;
      }
      .story {
        h3 {
          font-size: 24px;
          font-weight: 600;
          margin: 0;
        }
        display: flex;
        flex-direction: column;

        .source {
          font-size: 16px;
          font-weight: 600;
          margin-top: 8px;
          color: #323640;
          span {
            font-weight: 400;
            margin-left: 12px;
          }
          margin-bottom: 24px;
        }
        .submitter {
          display: flex;
          flex-direction: row;
          gap: 16px;
          img {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background-color: #cbd3f1;
          }
          div:nth-child(2) {
            font-family: @font-family-secondary;
            font-size: 16px;
            font-style: italic;
            font-weight: 400;
          }
        }
      }
    }
    .insights-news--previous-story {
      .ant-divider {
        margin: 0 20px;
      }
      div {
        color: #64748d;
        font-family: @font-family-secondary;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 12px */
        letter-spacing: 0.24px;
        margin-bottom: 8px;
      }
      h4 {
        color: #262626;
        font-family: @font-family-secondary;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
      }
    }
  }
}

@control-height-xs: 16px;
@control-height-sm: 24px;
@control-height-lg: 48px;

@border-radius-xxl: 44px;
@border-radius-lg: 8px;
@border-radius: 6px;
@border-radius-sm: 4px;
@border-radius-xs: 2px;

// Base Sizes
@size-2xxl: 96px;
@size-xxl: 48px;
@size-xl: 32px;
@size-lg: 24px;
@size-md: 20px;
@size: 16px;
@size-sm: 12px;
@size-xs: 8px;
@size-xxs: 4px;

@phone: 640px;

.border-radius-lg {
  border-radius: @border-radius-lg;
}

.border-radius {
  border-radius: @border-radius;
}

.border-radius-sm {
  border-radius: @border-radius-sm;
}

.border-radius-xs {
  border-radius: @border-radius-xs;
}

.gap-xxs {
  gap: @size-xxs;
}

.gap-xs {
  gap: @size-xs;
}

.gap-sm {
  gap: @size-sm;
}

.gap {
  gap: @size;
}

.gap-md {
  gap: @size-md;
}

.gap-lg {
  gap: @size-lg;
}

.gap-xl {
  gap: @size-xl;
}

.gap-xxl {
  gap: @size-xxl;
}

.gap-2xxl {
  gap: @size-2xxl;
}

.text-xxs {
  font-size: @size-xxs;
}

.text-xs {
  font-size: @size-xs;
}

.text-sm {
  font-size: @size-sm;
}

.text {
  font-size: @size !important;
}

.text-md {
  font-size: @size-md;
}

.text-lg {
  font-size: @size-lg;
}

.text-xl {
  font-size: @size-xl;
}

.text-xxl {
  font-size: @size-xxl;
}

.text-2xxl {
  font-size: @size-2xxl;
}

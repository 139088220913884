@import (reference) '/src/styles/Size.less';

.shipmentTitle {
  font-size: 18px;
  line-height: 24px;
}

.datePicker {
  max-width: 320px;
}

.radioButton {
  :global(.ant-radio-inner) {
    width: @size;
    height: @size;
  }
}

.inputShipment {
  min-width: 320px;
}

.contractTermsSupplier {
  max-width: 720px;
  margin: 60px 0;
}

@primary-color: #2F54EB;
.legal-section {
  max-width: 1000px;
  margin: 0 auto;
  padding: 120px 30px;
  table,
  th,
  td {
    border: 2px solid;
    border-collapse: collapse;
    padding: 1em;
    vertical-align: top;
  }
  li {
    margin-bottom: 12px;
  }
}

@import (reference) '/src/styles/Size.less';

.textIcon {
  font-size: @size;
}

.buttonText {
  font-size: @size-sm;
  font-weight: 400;
}

@primary-color: #2F54EB;
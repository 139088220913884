@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/ColorFigma.less';

.property {
  font-size: 14px;
  font-weight: 600;
  color: @gray-10;
}

.spectItemText {
  font-size: 14px;
  color: @text-primary;
  font-weight: 400;
}

.specCart {
  padding: @size-sm;
  border-radius: @size-xs;
  background-color: @Colors-Primatives-Neutral_Gray-3;
  margin-top: @size-sm;
}

@primary-color: #2F54EB;
@import (reference) '/src/pages/project/styles/Sidebar.less';
@import (reference) '/src/styles/Color.less';

.kpi-heatmap {
  display: flex;
  flex: 1;
  flex-direction: column;

  .chart-controls {
    // align-items: center;
    // justify-content: flex-end;
    width: 100%;
    gap: 8px;
    // margin-top: -50px;
    padding-bottom: 16px;
    padding-right: 16px;
    border-right: 1px solid @gray-5;

    > div {
      width: 240px;
    }
    .kpi-filter {
      .project-page .filters-sidebar .kpi-filter();
    }
  }
  svg {
    text {
      font-family: "Roboto";
    }
    .left-horiz {
      transform: rotate(0) translate(120px,25px);
      text-anchor: start;
    }
  }
}

@primary-color: #2F54EB;
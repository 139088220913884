.status-tag {
  white-space: nowrap;
  margin-left: 8px;
  padding: 1px 7px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  &.pending {
    border-color: #ffc069;
    color: #fa8c16;
  }
  &.accepted {
    border-color: #36b979;
    color: #447d61;
  }
  &.archived {
    border-color: #8c8c8c;
    color: #606060;
  }
  &.draft {
    border-color: #ffc069;
    color: #fa8c16;
  }
  &.rejected {
    border-color: #ffc069;
    color: #fa8c16;
  }
  &.pending-review {
    border-color: #8c8c8c;
    color: #606060;
  }
  &.approved {
    border-color: #36b979;
    color: #447d61;
  }
}

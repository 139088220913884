.company-header {
  height: 176px;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 10px 10px -10px #000000;
  margin-bottom: -120px;
}
.full-page-spin {
  margin: 100px auto;
  display: block;
}
.logo-wrap {
  width: 92px;
  height: 92px;
  padding: 8px;
  border: 1px solid #d1d1d1;
  border-radius: 50%;
  background-color: white;
  overflow: hidden;

  &.small {
    width: 48px;
    height: 48px;
  }

  &.large {
    width: 136px;
    height: 136px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.company-page {
  .logo-wrap {
    margin-bottom: 24px;
  }
  .ant-space {
    width: 100%;
  }
  .badges-card {
    border-radius: 10px;
    margin-top: 24px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    h3.ant-typography {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 24px;
      margin: 8px 0 16px;
    }
    .ant-divider-horizontal {
      border-color: #999;
    }
    @media (min-width: @md-bp) {
      margin-top: -24px;
    }
  }
  h2.ant-typography {
    font-size: 30px;
  }
  div.ant-typography {
    font-weight: 300;
    color: @gray-7;
  }
  .commodities-section {
    margin-bottom: 20px;
    .title {
      .ant-typography {
        margin-bottom: 0;
        line-height: normal;
        &:not(:first-child) {
          margin-left: 16px;
          font-weight: 300;
          a {
            text-decoration: underline;
          }
        }
      }
    }
    .ant-pagination {
      display: none;
    }
  }
  .certifications-section {
    .images {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      img {
        margin: 0 8px 8px;
      }
    }
  }
}
.company-location {
  background-color: @gray-2;
  border: 1px solid @gray-3;
  border-radius: 4px;
  margin-right: 24px;
  margin-bottom: 16px;
  padding: 16px 16px;
  font-size: 16px;
  font-weight: 300;
  h4.ant-typography {
    font-size: 12px;
  }
}
.badge {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 18px;
  .ant-typography-secondary {
    font-size: 12px;
    font-weight: 300;
  }
}

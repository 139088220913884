@import (reference) '/src/styles/Color.less';
@import (reference) '/src/styles/Size.less';
@import (reference) '/src/styles/ColorFigma.less';

.generalInformation {
  font-size: 18px;
  font-weight: 600;
  color: @color-gray-9;
  margin-top: @size-xxl;
  margin-bottom: @size-lg;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: @gray-10;
  margin-bottom: @size-xs;
}

.description {
  font-size: 14px;
  color: @Text-primary;
}

@primary-color: #2F54EB;
.splash-page.banner {
  .splash-container {
    display: flex;
    flex-direction: row;

    .splash-banner {
      flex: 0 0 600px;
      display: flex;
      flex-direction: column;
      background-size: cover;
      color: #fff;
      padding: 52px 90px 29px 64px;
      justify-content: space-between;
      button.splash-banner-back {
        color: #fff;
        height: 24px;
        width: 96px;
        .anticon {
          border: 1px solid rgba(255, 255, 255, 0.25);
          border-radius: 8px;
          font-size: 12px;
          line-height: 25px;
          padding: 0 5px;
          margin-right: 24px;
          height: 24px;
          width: 24px;
        }
        margin-bottom: 16px;
      }
      .splash-banner-body {
        .splash-banner-content {
          padding-bottom: 30px;
          .splash-banner-title {
            .heading-3xl();
            color: #fff;
          }
          .splash-banner-subtitle {
            .heading-lg();
            padding-top: 16px;
            color: #fff;
            font-weight: 300;
          }
        }
      }
      .splash-banner-footer {
        flex: 0 0 18px;
        .font-size-2();
      }
    }
    .splash-content {
      flex: 1;
      height: 100%;
      padding-bottom: 64px;
    }
  }

  ul.bullet-list {
    list-style-type: none;
    padding-inline-start: 0px;
    .bullet-list-item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .anticon {
        padding-top: 5px;
        font-size: 21px;
        padding-right: 17.5px;
      }
    }
  }

  ol.numbered-list {
    list-style-type: none;
    padding-inline-start: 0px;
    .numbered-list-item {
      display: flex;
      flex-direction: row;
      .list-item-number {
        flex: 0 0 24px;
        margin-right: 24px;
        height: 24px;
        width: 24px;
        border: 1px solid #fff;
        border-radius: 50%;
        text-align: center;
      }
    }
  }
}

@import (reference) '/src/styles/Color.less';

.simple-header-container {
  z-index: 100;
  position: fixed;
  width: 100%;
  &.scrolling {
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);

    .simple-header {
      .hamburger {
        color: @text-primary;
      }

      .ant-menu .ant-menu-item,
      .ant-menu .ant-menu-submenu {
        color: @text-primary;
      }
    }
  }

  .ant-dropdown-menu-item-danger {
    color: @blue-7;
  }
  .ant-dropdown-menu-item-danger:hover {
    background-color: @blue-7;
    color: #fff;
  }

  .gradient-border {
    background: linear-gradient(
      90deg,
      #bfc519 0%,
      #00bf80 33.33%,
      #4b73d8 66.67%,
      #ae38da 100%
    );
    height: 4px;
    width: 100%;
  }

  .simple-header {
    align-items: center;
    width: 1224px;
    max-width: 100%;
    margin: 0 auto;
    padding: 26px 24px;
    // height: 80px;
    z-index: 100;
    @media (min-width: @lg-bp) {
      padding-left: 72px;
      padding-right: 72px;
    }

    &.compact {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .logo {
      display: inline-block;
      height: 20px;
      cursor: pointer;

      @media (min-width: @md-bp) {
        height: 28px;
      }
      @media (min-width: @lg-bp) {
        height: 32px;
      }

      > svg {
        height: 100%;
        width: auto;
      }
    }

    .link {
      color: white;
    }

    .row-item {
      color: white;

      &.center-items-container,
      &.right-items-container {
        flex-grow: 1;
        > ul {
          justify-content: flex-end;
          align-items: center;

          > li {
            margin-left: 40px;

            @media (min-width: @xl-bp) {
              margin-left: 72px;
            }

            &:first-child,
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }

    .hamburger-container {
      text-align: right;
    }

    .ant-menu {
      background-color: rgba(0, 0, 0, 0);
      color: #fff;
      .ant-menu-item,
      .ant-menu-submenu {
        text-align: center;
        font-weight: 500;
        color: #fff;
        padding: 0;
        font-size: 14px;
        line-height: 28px;
        cursor: pointer;

        @media (min-width: @lg-bp) {
          font-size: 16px;
          line-height: 28px;
        }

        &::after {
          border: none;
        }

        &.ant-menu-item-selected::after {
          border: none;
        }

        &.ant-menu-item-danger {
          background-color: @blue-7;
          // Any br > than element's dimensions will give it the "pellet" look
          border-radius: 100px;
          color: #fff;
          padding: 5px 16px;
          margin-right: -24px !important;

          @media (min-width: @xl-bp) {
            margin-right: -48px !important;
          }
        }
      }
      .ant-menu-submenu-active {
        &::after {
          border: none;
        }
      }
      .ant-menu-submenu-selected {
        &::after {
          border: none;
        }
      }
      border: none;
    }

    .hamburger {
      color: #fff;
      font-size: 21px;
    }
  }
}

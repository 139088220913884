@import (reference) './Color.less';
@import (reference) './Breakpoint.less';

@HEADER-HEIGHT: 67px;

.digital-rfq {
  &--header {
    padding: 16px 40px;
    background: white;
    border-bottom: 1px solid @gray-5;
    width: 100%;
    height: @HEADER-HEIGHT;

    @media (max-width: @phone) {
      height: unset;
    }
  }

  &--content {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    height: calc(100vh - @HEADER-HEIGHT);
    overflow-y: scroll;
    min-width: 650px;

    @media (min-width: @xl-bp) {
      padding: 60px;
    }

    .digital-rfq--document-wrapper {
      width: 100%;
      max-width: 900px;
      flex-shrink: 0;

      .react-pdf__Document {
        width: 100%;
      }

      .react-pdf__Page {
        width: 100%;
        border: 1px solid @gray-5;
        border-radius: 6px;
        margin-bottom: 24px;
        overflow: hidden;
        box-sizing: border-box;
      }
    }
  }

  &--sidebar {
    background: white;
    padding: 40px 24px;
    border-left: 1px solid @gray-5;
    height: calc(100vh - @HEADER-HEIGHT);
    overflow-y: scroll;

    &--inner {
      max-width: 452px;
      margin: 0 auto;
    }

    h4 {
      font-family: @font-family-secondary;
      margin-bottom: 8px;

      + .ant-typography {
        margin-bottom: 24px;
      }
    }
  }

  &--confirmation {
    background: white;
    padding: 40px;
    width: 100%;
    text-align: center;

    &--inner {
      width: 100%;
      max-width: 550px;
      display: inline-block;
    }
    &--connect {
      text-align: left;
    }

    h1 {
      margin-bottom: 12px;
    }

    h3 {
      margin-bottom: 12px;
    }

    div.ant-typography {
      font-size: 16px;
    }

    .color-card {
      width: 100%;
      padding: 40px 24px;
      margin-top: 60px;
    }

    .ant-btn-primary {
      margin-top: 24px;
    }
  }
}

.buyer-digital-rfq {
  .react-pdf__Document {
    width: 100%;
    max-width: 900px;
  }

  .react-pdf__Page {
    width: 100%;
    border: 1px solid @gray-5;
    border-radius: 6px;
    margin-bottom: 24px;
    overflow: hidden;
    box-sizing: border-box;
  }
}

.hiddenCanvasElement {
  position: absolute;
}
